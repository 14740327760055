import React, { useState } from 'react';
import { FaFilePdf } from 'react-icons/fa';
import S3 from 'react-s3';
import { Success, Error } from 'Util/Toast';
import AddUserClasses from '../../users/addUser/AddUser.module.css';
import TutorClasses from '../../Categories/addCategory/index.module.css';
import CouponClasses from '../../coupons/Coupon.module.css';

window.Buffer = window.Buffer || require('buffer').Buffer;

const config = {
    bucketName: process.env.REACT_APP_BUCKETNAME,
    dirName: 'productImages',
    region: 'ap-south-1',
    accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
};

const AddProductGallery = ({ userData, setUserData, errors, validate }) => {
    const [fileData, setFileData] = useState([]);
    const [videoUrls, setVideoUrls] = useState(userData.productVideos || []);
    const [newVideoUrl, setNewVideoUrl] = useState('');

    const handleFileChange = async (event) => {
        const files = Array.from(event.target.files);
        const filePreviews = files.map(file => ({
            file,
            preview: URL.createObjectURL(file),
        }));

        setFileData(prevFileData => prevFileData.concat(filePreviews));

        for (const file of files) {
            const timestamp = Date.now();
            const newFile = new File([file], `${timestamp}.${String(file.type).split('/')[1]}`);

            try {
                const res = await S3.uploadFile(newFile, config);
                const endpoint = res.location.split('/').pop(); // Extract the endpoint
                setUserData(prevUserData => ({
                    ...prevUserData,
                    productImage: [...prevUserData.productImage, endpoint]
                }));
                Success('File uploaded successfully');
            } catch (uploadError) {
                Error('Failed to upload file');
            }
            validate('productImage', files.length > 0);
        }
    };

    const handleDelete = (index) => {
        const updatedFiles = [...fileData];
        updatedFiles.splice(index, 1);
        setFileData(updatedFiles);

        const updatedImages = [...userData.productImage];
        updatedImages.splice(index, 1);
        setUserData(prevUserData => ({
            ...prevUserData,
            productImage: updatedImages
        }));
    };

    const handleVideoUrlChange = (e) => {
        setNewVideoUrl(e.target.value);
    };

    const addVideoUrl = () => {
        if (newVideoUrl) {
            setVideoUrls(prevUrls => [...prevUrls, newVideoUrl]);
            setUserData(prevUserData => ({
                ...prevUserData,
                productVideos: [...prevUserData.productVideos, newVideoUrl]
            }));
            setNewVideoUrl('');
        }
    };

    const removeVideoUrl = (index) => {
        const updatedUrls = videoUrls.filter((_, i) => i !== index);
        setVideoUrls(updatedUrls);
        setUserData(prevUserData => ({
            ...prevUserData,
            productVideos: updatedUrls
        }));
    };

    return (
        <div>
            <table className={AddUserClasses['user-table']}>
                <thead>
                    <tr>
                        <th colSpan="2" className={AddUserClasses['table-header']}>Basic Details</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className={AddUserClasses['table-value']}>
                            <div className={TutorClasses['upload-image']}>
                                {fileData.length > 0 && fileData.map((file, index) => (
                                    <div key={index} style={{ position: 'relative', display: 'inline-block' }}>
                                        <img
                                            src={file.preview}
                                            alt={`Selected ${index}`}
                                            className={CouponClasses['uploaded-coupon-image']}
                                        />
                                        <button
                                            onClick={() => handleDelete(index)}
                                            style={{
                                                position: 'absolute',
                                                top: '5px',
                                                right: '0px',
                                                left: '5px',
                                                height: '20px',
                                                width: '20px',
                                                backgroundColor: 'red',
                                                color: 'white',
                                                border: 'none',
                                                borderRadius: '50%',
                                                cursor: 'pointer',
                                                marginRight: '10px'
                                            }}
                                        >
                                            &times;
                                        </button>
                                    </div>
                                ))}
                                <div className={TutorClasses['add-item']} onClick={() => document.getElementById("file-input").click()}>
                                    <FaFilePdf className={TutorClasses['upload-icon']} />
                                    <h5>Drag & Drop</h5>
                                    <p>PDF</p>
                                    <p>or</p>
                                    <button className={AddUserClasses['Browse-btn']}>Browse</button>
                                    <p>(Only *.jpeg, *.png will be accepted)</p>
                                </div>
                                <input
                                    type="file"
                                    id="file-input"
                                    onChange={handleFileChange}
                                    style={{ display: 'none' }}
                                    multiple
                                />
                                {errors.productImage && <p style={{ color: 'red' }}>{errors.productImage}</p>}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className={AddUserClasses['table-value']}>
                            <div className={AddUserClasses['video-inp-box']}>
                                <input
                                    type="text"
                                    value={newVideoUrl}
                                    onChange={handleVideoUrlChange}
                                    placeholder="Enter YouTube video URL"
                                    className={AddUserClasses['table-textfield']}
                                />
                                <button onClick={addVideoUrl} className={AddUserClasses['Browse-btn']}>
                                    Add Video URL
                                </button>
                            </div>
                            <ul className={AddUserClasses['video-para-main']}>
                                {videoUrls.map((url, index) => (
                                    <li key={index}>
                                        {url}
                                        <button onClick={() => removeVideoUrl(index)} style={{ marginLeft: '10px', color: 'red' }}>
                                            Remove
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default AddProductGallery;
