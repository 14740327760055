import React, { useState, useEffect } from 'react';
import ViewUserClasses from './ViewUsers.module.css';
import { useParams } from 'react-router-dom';
import { Services } from 'Services';
import { Skeleton, Stack } from '@mui/material';
import moment from 'moment';

const UserDocuments = () => {
    const { userId } = useParams();
    const [documents, setDocuments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const token = { Authorization: `Bearer ${localStorage.getItem('token')}` };
                const response = await Services.getUserDetails(userId, token);
                console.log('API Response:', response); // Logging the API response
                if (response.user && response.user.documents) {
                    setDocuments(response.user.documents); // Assuming the documents are in response.user.documents
                } else {
                    throw new Error('No Documents Found');
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchUserDetails();
    }, [userId]);

    if (loading) {
        return (
            <div>
                <table className={ViewUserClasses['user-table']}>
                    <thead>
                        <tr>
                            <th colSpan="2" className={ViewUserClasses['table-header']}>Documents</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan="2">
                                <div style={{ padding: '5px' }} className={ViewUserClasses['skeleton-viewuseroverview']}>
                                    <Skeleton variant="rectangular" width={300} height={30} />
                                    <Skeleton variant="rectangular" width={300} height={30} />
                                    <Skeleton variant="rectangular" width={300} height={30} />
                                    <Skeleton variant="rectangular" width={300} height={30} />
                                    <Skeleton variant="rectangular" width={300} height={30} />
                                    <Skeleton variant="rectangular" width={300} height={30} />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table className={ViewUserClasses['user-table']}>
                    <thead>
                        <tr>
                            <th colSpan="2" className={ViewUserClasses['table-header']}>Documents</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan="2">
                                <div style={{ padding: '5px' }} className={ViewUserClasses['skeleton-viewuseroverview']}>
                                    <Skeleton variant="rectangular" width={300} height={30} />
                                    <Skeleton variant="rectangular" width={300} height={30} />
                                    <Skeleton variant="rectangular" width={300} height={30} />
                                    <Skeleton variant="rectangular" width={300} height={30} />
                                    <Skeleton variant="rectangular" width={300} height={30} />
                                    <Skeleton variant="rectangular" width={300} height={30} />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (documents.length === 0 || null) {
        return <div>No documents found.</div>;
    }

    // Filter documents for the first table
    const firstTableDocs = documents.filter(doc => doc.name === 'Aadhar Card' || doc.name === 'Pan Card');

    // Filter documents for the second table
    const secondTableDocs = documents.filter(doc => doc.name === 'Drug License' || doc.name === 'Support Documents');

    const formatDate = (dateString) => {
        // console.log(dateString)
        return moment(dateString).format("DD-MM-YYYY");
    };

    return (
        <div>
            <table className={ViewUserClasses['docs-user-table']}>
                <thead>
                    <tr>
                        <th className={ViewUserClasses['docs-table-header']}>Name</th>
                        <th className={ViewUserClasses['docs-table-header']}>Status</th>
                        <th className={ViewUserClasses['docs-table-header']}>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {firstTableDocs.map((doc) => (
                        <tr key={doc.documentId || doc.name}>
                            <td className={ViewUserClasses['docs-table-value']}>{doc.name}</td>
                            <td className={ViewUserClasses['docs-table-value']}>
                                <span className={
                                    doc.status === 1
                                        ? ViewUserClasses['docs-status-verified']
                                        : ViewUserClasses['docs-status-in-progress']
                                }>
                                    {doc.status === 1 ? 'Verified' : 'In Progress'}
                                </span>
                            </td>
                            <td className={ViewUserClasses['docs-table-value']}>
                                <a href={doc.document} target="_blank" rel="noopener noreferrer">View</a> | <a href={doc.document} target="_blank" rel="noopener noreferrer">Download</a>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <table className={ViewUserClasses['docs-user-table']}>
                <thead>
                    <tr>
                        <th className={ViewUserClasses['docs-table-header']}>Name</th>
                        <th className={ViewUserClasses['docs-table-header']}>Status</th>
                        <th className={ViewUserClasses['docs-table-header']}>Expiry Date</th>
                        <th className={ViewUserClasses['docs-table-header']}>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {secondTableDocs.map((doc) => (
                        <tr key={doc.documentId || doc.name}>
                            <td className={ViewUserClasses['docs-table-value']}>{doc.name}</td>
                            <td className={ViewUserClasses['docs-table-value']}>
                                <span className={
                                    doc.status === 1
                                        ? ViewUserClasses['docs-status-verified']
                                        : ViewUserClasses['docs-status-in-progress']
                                }>
                                    {doc.status === 1 ? 'Verified' : 'In Progress'}
                                </span>
                            </td>
                            <td className={ViewUserClasses['docs-table-value']}>
                                {doc.expiryDate ? formatDate(doc.expiryDate === "0000-00-00" ? 'NA' : doc.expiryDate) : 'NA'}
                            </td>
                            <td className={ViewUserClasses['docs-table-value']}>
                                <a href={doc.document} target="_blank" rel="noopener noreferrer">View</a> | <a href={doc.document} target="_blank" rel="noopener noreferrer">Download</a>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default UserDocuments;
