import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { DataGrid } from '@mui/x-data-grid';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Menu, MenuItem, Chip, Avatar, InputBase } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from 'react-router-dom';
import Layout from 'Components/Layout';
import SkeletonLoad from 'Components/TableLoad/load';
import { Services } from 'Services';
import noProduct from '../../Assets/no-product1.png';
import CommonClasses from 'Styles/Common.module.css';
import ProductClasses from '../Product/Products.module.css';
import UserClasses from '../users/Users.module.css';
import { BiPlus } from 'react-icons/bi';
import medicalimg from '../../Assets/medical-3.jpg';
import ayurveda1 from '../../Assets/ayurveda-1.png';
import skincare1 from '../../Assets/skincare-1.jpg';
import surgical1 from '../../Assets/surgical1.jpg';
import { Success, Error_Dark } from 'Util/Toast';
import SearchIcon from '@mui/icons-material/Search';
import ViewUserClasses from '../users/viewUsers/ViewUsers.module.css';

const Product = () => {
    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pageSize, setPageSize] = useState(10);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [selectedProductId, setSelectedProductId] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const token = { Authorization: `token ${localStorage.getItem('token')}` };
                const response = await Services.getAllProducts(token);
                if (response.Status === 1) {
                    setProducts(response.products);
                    setFilteredProducts(response.products); // Initialize filteredProducts with all products
                }
                setLoading(false);
            } catch (error) {
                console.error('Error fetching products:', error);
                setLoading(false);
            }
        };

        fetchProducts();
    }, []);

    useEffect(() => {
        filterProducts(searchQuery);
    }, [searchQuery, products]);

    const handleMenuOpen = (event, productId) => {
        event.stopPropagation(); // Prevent the row click event from firing
        setMenuAnchorEl(event.currentTarget);
        setSelectedProductId(productId);
    };

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };

    const handleEditProduct = () => {
        const product = products.find(p => p.productId === selectedProductId);
        navigate(`/edit-product/${selectedProductId}`, { state: { product } });
        handleMenuClose();
    };

    const handleOpenDeleteDialog = () => {
        setDeleteDialogOpen(true);
        handleMenuClose();
    };

    const handleCloseDeleteDialog = () => {
        setDeleteDialogOpen(false);
    };

    const handleDeleteProduct = async () => {
        try {
            const token = { Authorization: `token ${localStorage.getItem('token')}` };
            await Services.deleteProduct(selectedProductId, token);
            setProducts(products.filter(product => product.productId !== selectedProductId));
            setFilteredProducts(filteredProducts.filter(product => product.productId !== selectedProductId));
            handleCloseDeleteDialog();
            Success('Product Deleted Successfully');
        } catch (error) {
            console.error('Error deleting product:', error);
        }
    };

    const handleStatusChange = async () => {
        const product = products.find(p => p.productId === selectedProductId);
        const newIsActive = product.isActive === 1 ? 0 : 1; // Toggle isActive value
    
        const token = { Authorization: `token ${localStorage.getItem('token')}` };
    
        try {
            const response = await Services.makeProductOnlineOffline(selectedProductId, newIsActive, token);
            
            if (response.Status === 1) {
                // Update the product status locally
                setProducts(prevProducts =>
                    prevProducts.map(product =>
                        product.productId === selectedProductId ? { ...product, isActive: newIsActive } : product
                    )
                );
                Success('Product status updated successfully');
            } else {
                Error_Dark('Failed to update product status');
            }
        } catch (error) {
            console.error('Error updating product status:', error);
            Error_Dark('Error updating product status');
        }
    
        handleMenuClose(); // Close the menu after handling status change
    };
    

    const formatDiscount = (params) => {
        return `${params.value}%`;
    };

    const formatSellingPrice = (params) => {
        return `₹${params.value}`;
    };

    const renderCategoryChip = (categoryName) => {
        let avatarSrc = '';
        let chipColor = '#757575';

        switch (categoryName.toLowerCase()) {
            case 'medical':
                avatarSrc = medicalimg;
                chipColor = '#60E19B';
                break;
            case 'surgical':
                avatarSrc = surgical1;
                chipColor = '#BBDED6';
                break;
            case 'ayurveda':
                avatarSrc = ayurveda1;
                chipColor = '#FFB6B9';
                break;
            case 'skin care':
                avatarSrc = skincare1;
                chipColor = '#FAE3D9';
                break;
            default:
                avatarSrc = '/path/to/default_avatar.png';
                break;
        }

        return (
            <Chip
                avatar={<Avatar alt={categoryName} src={avatarSrc} />}
                label={categoryName}
                style={{ backgroundColor: chipColor, color: 'white', fontWeight: 'bold' }}
            />
        );
    };

    const columns = [
        {
            field: 'productImage',
            headerName: 'Image',
            width: 200,
            renderCell: (params) => (
                <img
                    src={params.value[0]}
                    alt={params.row.productName}
                    style={{ width: '50px', height: '50px', objectFit: 'cover' }}
                />
            ),
        },
        { field: 'productName', headerName: 'Product Name', width: 200 },
        {
            field: 'categoryName',
            headerName: 'Category',
            width: 200,
            renderCell: (params) => renderCategoryChip(params.value),
        },
        { field: 'productPrice', headerName: 'MRP', width: 200 },
        { field: 'discount', headerName: 'Discount', width: 200, renderCell: (params) => formatDiscount(params) },
        { field: 'stockQty', headerName: 'Stock', width: 200 },
        { field: 'productDiscountPrice', headerName: 'Selling Price', width: 200, renderCell: (params) => formatSellingPrice(params) },
        {
            field: 'isActive',
            headerName: 'Online/Offline',
            width: 200,
            renderCell: (params) => (
                <span style={{ color: params.row.isActive === 1 ? 'green' : 'gray' }}>
                    {params.row.isActive === 1 ? 'Online' : 'Offline'}
                </span>
            ),
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 150,
            renderCell: (params) => (
                <div>
                    <IconButton
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={(event) => handleMenuOpen(event, params.row.productId)}
                    >
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        id="simple-menu"
                        anchorEl={menuAnchorEl}
                        keepMounted
                        open={Boolean(menuAnchorEl && selectedProductId === params.row.productId)}
                        onClose={handleMenuClose}
                        sx={{ boxShadow: 'none' }}
                    >
                        <MenuItem onClick={handleEditProduct}>Edit</MenuItem>
                        <MenuItem onClick={handleStatusChange}>{params.row.isActive === 1 ? 'Set Offline' : 'Set Online'}</MenuItem>
                        <MenuItem onClick={handleOpenDeleteDialog}>Delete</MenuItem>
                    </Menu>
                </div>
            ),
        },
    ];

    const handleSelectionModelChange = (newSelection) => {
        setSelectedProducts(newSelection);
    };

    const handleRowClick = (params) => {
        navigate(`/view-products/${params.row.productId}`, { state: { user: params.row } });
    };

    const filterProducts = (query) => {
        const lowerCaseQuery = query.toLowerCase();
        const filtered = products.filter(product =>
            product.productName.toLowerCase().includes(lowerCaseQuery) ||
            product.sku.toLowerCase().includes(lowerCaseQuery)
        );
        setFilteredProducts(filtered);
    };

    return (
        <Layout Active={'Products'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Products</title>
            </Helmet>
            <div className={CommonClasses['Container']}>
                <div className={ViewUserClasses['search-new-btn']}>
                    <div>
                        <h3 className={ProductClasses['main-header']}>Products<span className={ProductClasses['count-of-users']}>({products.length})</span></h3>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', width: '400px' }}>
                        <SearchIcon className={ViewUserClasses['searchicon']} />
                        <InputBase
                            placeholder="Product Name/SKU"
                            inputProps={{ 'aria-label': 'search products' }}
                            style={{
                                marginLeft: '10px',
                                flex: 1,
                                padding: '5px 35px',
                                border: '1px solid #ddd',
                                borderRadius: '4px',
                                textIndent: '60px'
                            }}
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </div>
                </div>
                <div className={CommonClasses['control-btns']}>
                    <Button style={{ marginBottom: '20px' }}
                        className={CommonClasses['button-for-add']}
                        onClick={() => {
                            navigate("/addproduct");
                        }}>
                        <BiPlus size={20} /> Add Product
                    </Button>
                </div>
                {loading ? (
                    <SkeletonLoad
                        TableHead={["PHOTO", "FULL NAME", "EMAIL", "MOBILE NUMBER", "SHOP NAME"]}
                    />
                ) : filteredProducts.length > 0 ? (
                    <div className={CommonClasses['Table']} style={{ maxHeight: 600 }}>
                        <DataGrid
                            rows={filteredProducts}
                            columns={columns}
                            pageSize={pageSize}
                            rowsPerPageOptions={[10, 25, 50, { value: -1, label: 'All' }]}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            pagination
                            getRowId={(row) => row.productId}
                            checkboxSelection
                            onSelectionModelChange={handleSelectionModelChange}
                            onRowClick={handleRowClick}
                        />
                        {selectedProducts.length > 0 && (
                            <div>
                                <p>Selected Products: {selectedProducts.join(', ')}</p>
                            </div>
                        )}
                    </div>
                ) : (
                    <div>
                        <p>No Products Found</p>
                    </div>
                )}

                <Dialog
                    open={deleteDialogOpen}
                    onClose={handleCloseDeleteDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Delete Product"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to delete this product?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDeleteDialog} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleDeleteProduct} color="primary" autoFocus>
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </Layout>
    );
};

export default Product;
