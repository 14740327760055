import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Button, Breadcrumbs, Link, Typography, Stack, Tabs, Tab, Box } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { MdOutlineArrowBackIos } from "react-icons/md";
import { useNavigate, useParams } from 'react-router-dom';
import Layout from 'Components/Layout';
import ViewUserClasses from '../../users/viewUsers/ViewUsers.module.css';
import CommonClasses from '../../../Styles/Common.module.css';
import AddUserClasses from '../../users/addUser/AddUser.module.css';
import EditProductOverview from './EditProductOverview';
import EditProductDescription from './EditProductDescription';
import EditProductGallery from './EditProductGallery';
import EditProductPricing from './EditProductPricing';
import { Services } from 'Services';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
};

const EditProduct = () => {
    const navigate = useNavigate();
    const { productId } = useParams(); // Fixing the useParams hook usage
    const [activeTab, setActiveTab] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [product, setProduct] = useState(null); // Single product

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" onClick={() => navigate('/')}>
            Home
        </Link>,
        <Link underline="hover" key="2" color="inherit" onClick={() => navigate('/products')}>
            Products
        </Link>,
        <Typography key="3" color="text.primary">
            Edit Product
        </Typography>,
    ];

    useEffect(() => {
        const fetchProductDetails = async () => {
            try {
                const token = { Authorization: `Bearer ${localStorage.getItem('token')}` };
                const response = await Services.getProductDetails(productId, token);
                setProduct(response.product); // Assuming response contains a single product object
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchProductDetails();
    }, [productId]);

    return (
        <Layout Active={'Products'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Edit Product</title>
            </Helmet>
            <div className={AddUserClasses['main-of-tables']}>
                <div className={AddUserClasses['new-main-div']}>
                    <div className={ViewUserClasses['main-on-ctrl-btns']}>
                        <div className={CommonClasses['control-btns']}>
                            <Button className={CommonClasses["button-for-add"]} onClick={() => navigate(-1)}>
                                <MdOutlineArrowBackIos />Back
                            </Button>
                        </div>
                        <Stack spacing={2}>
                            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                                {breadcrumbs}
                            </Breadcrumbs>
                        </Stack>
                    </div>
                </div>
                {loading ? (
                    <p>Loading...</p>
                ) : error ? (
                    <p>{error}</p>
                ) : product ? (
                    <div className={ViewUserClasses['main-name']}>
                        <div className={ViewUserClasses['inside-leftname']}>
                            <h1 className={ViewUserClasses['user-fullname']}>{product.productName}</h1>
                            <h1 className={ViewUserClasses['user-fullname']}>{product.metricValue}{product.metricType}</h1>
                        </div>
                    </div>
                ) : (
                    <p>No product found</p>
                )}
            </div>
            <div className={AddUserClasses['main-prod-second-container']}>
                <Tabs value={activeTab} onChange={handleTabChange} aria-label="edit product tabs">
                    <Tab label="Overview" />
                    <Tab label="Description" />
                    <Tab label="Gallery" />
                    <Tab label="Pricing" />
                </Tabs>
                <TabPanel value={activeTab} index={0}>
                    <EditProductOverview product={product} />
                </TabPanel>
                <TabPanel value={activeTab} index={1}>
                    <EditProductDescription product={product} />
                </TabPanel>
                <TabPanel value={activeTab} index={2}>
                    <EditProductGallery product={product} />
                </TabPanel>
                <TabPanel value={activeTab} index={3}>
                    <EditProductPricing product={product} />
                </TabPanel>
            </div>
        </Layout>
    );
};

export default EditProduct;
