import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Button, Breadcrumbs, Link, Typography, Stack, Tabs, Tab, Box } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { MdOutlineArrowBackIos } from "react-icons/md";
import { useNavigate, useParams } from 'react-router-dom';
import Layout from 'Components/Layout';
import ViewUserClasses from '../../users/viewUsers/ViewUsers.module.css';
import CommonClasses from '../../../Styles/Common.module.css';
import AddUserClasses from '../../users/addUser/AddUser.module.css';
import EditOrderCustomerAndShipping from './EditOrderCustomerAndShipping';
import EditOrderGeneral from './EditOrderGeneral';
import EditOrderProducts from './EditOrderProducts';
import { Services } from 'Services';
import { Success } from 'Util/Toast';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
};

const EditOrder = () => {
    const navigate = useNavigate();
    const { orderId } = useParams();
    console.log(orderId, 'orderid..')
    const [activeTab, setActiveTab] = useState(0);
    const [paymentType, setPaymentType] = useState(1); // Default to Cash
    const [orderData, setOrderData] = useState({});
    const [userData, setUserData] = useState({});
    const [deliveryAddress, setDeliveryAddress] = useState({}); // State for delivery address
    const [productsData, setProductsData] = useState([]); // State for products data
    const [order, setOrder] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);


    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const handlePaymentTypeChange = (event) => {
        setPaymentType(event.target.value);
    };

    const handleUpdate = (order, user, address) => {
        setOrderData(order);
        setUserData(user);
        setDeliveryAddress(address); // Set delivery address
    };

    const handleProductsUpdate = (products) => {
        setProductsData(products); // Set products data
    };

    const handleSave = () => {
        const token = { Authorization: `Bearer ${localStorage.getItem('token')}` };
        const payload = {
            ...orderData,
            ...userData,
            deliveryAddress,
            paymentType,
            products: productsData, // Include products data in the payload
        };

        Services.editOrder(orderId, payload, token)
            .then(response => {
                console.log('Order updated successfully', response);
                // Handle success, e.g., show a success message or redirect
                Success(response.Message)
            })
            .catch(error => {
                console.error('Error updating order', error);
                // Handle error, e.g., show an error message
            });
    };

    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" onClick={() => navigate('/')}>
            Home
        </Link>,
        <Link underline="hover" key="2" color="inherit" onClick={() => navigate('/orders')}>
            Orders
        </Link>,
        <Typography key="3" color="text.primary">
            Edit Order
        </Typography>,
    ];

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const token = { Authorization: `Bearer ${localStorage.getItem('token')}` };
                const response = await Services.getOrderDetails(orderId, token);
                setOrder(response.order); // Adjust this line based on your API response structure
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchUserDetails();
    }, [orderId]);

    const handleGenerateInvoice = () => {
        if (order && order.invoice) {
            window.open(order.invoice, '_blank');
        } else {
            console.error("Invoice URL not available");
        }
    };

    return (
        <Layout Active={'Orders'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Edit Order</title>
            </Helmet>
            <div className={AddUserClasses['main-of-tables']}>
                <div className={AddUserClasses['new-main-div']}>
                    <div className={ViewUserClasses['main-on-ctrl-btns']}>
                        <div className={CommonClasses['control-btns']}>
                            <Button className={CommonClasses["button-for-add"]} onClick={() => navigate(-1)}>
                                <MdOutlineArrowBackIos />Back
                            </Button>
                        </div>
                        <Stack spacing={2}>
                            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                                {breadcrumbs}
                            </Breadcrumbs>
                        </Stack>
                    </div>
                </div>
                {order ? (
                    <div className={ViewUserClasses['main-name']}>
                        <div className={ViewUserClasses['inside-leftname']}>
                            <h1 className={ViewUserClasses['user-fullname']}>{order.orderId}</h1>
                            <p className={ViewUserClasses['user-para-stat']}>{order.orderDeliveryStatus === 1 ? 'New Order' : order.orderDeliveryStatus === 2 ? 'Ready for pickup' : order.orderDeliveryStatus === 3 ? 'Shipped' : order.orderDeliveryStatus === 4 ? 'Delivered' : order.orderDeliveryStatus === 5 ? 'Returned' : 'NA'}</p>
                            <p style={{ backgroundColor: order.paymentType === 1 ? '#60E19B' : '#FFE600', color: 'white' }} className={ViewUserClasses['user-para-stat']}>{order.paymentType === 1 ? 'Paid' : 'Un Paid'}</p>
                        </div>
                        <div className={ViewUserClasses['inside-rightname']}>
                            <button className={ViewUserClasses['invoice-btn']} onClick={handleGenerateInvoice}>Generate Invoice</button>
                        </div>
                    </div>
                ) : ''}
            </div>
            <div className={AddUserClasses['main-prod-second-container']}>
                <Tabs value={activeTab} onChange={handleTabChange} aria-label="edit product tabs">
                    <Tab label="General" />
                    <Tab label="Customer And Shipping" />
                    <Tab label="Products" />
                </Tabs>
                <TabPanel value={activeTab} index={0}>
                    <EditOrderGeneral
                        paymentType={paymentType}
                        onPaymentTypeChange={handlePaymentTypeChange}
                    />
                </TabPanel>
                <TabPanel value={activeTab} index={1}>
                    <EditOrderCustomerAndShipping
                        onUpdate={handleUpdate}
                        orderData={orderData}
                        userData={userData}
                        deliveryAddress={deliveryAddress}
                    />
                </TabPanel>
                <TabPanel value={activeTab} index={2}>
                    <EditOrderProducts
                        handleSaveChanges={handleProductsUpdate}
                        productsData={productsData}
                    />
                </TabPanel>
            </div>
        </Layout>
    );
};

export default EditOrder;
