import React, { useEffect, useState } from 'react';
import { Grid, TextField, Typography, MenuItem, Select, FormControl, InputLabel, Button } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { Services } from 'Services';
import { useParams, useNavigate } from 'react-router-dom';
import AddUserClasses from '../../users/addUser/AddUser.module.css';
import EditProdClasses from './EditProduct.module.css';
import { Hourglass } from 'react-loader-spinner';
import EditOrderGeneralClasses from '../../orders/editOrder/EditOrder.module.css';
import { Success } from 'Util/Toast';

const EditProductOverview = () => {
    const { productId } = useParams();
    const navigate = useNavigate();
    const [productData, setProductData] = useState({
        productName: '',
        productCategoryId: '',
        productPrice: '',
        discount: '',
        stockQty: '',
        productDiscountPrice: '',
        isActive: '',
        brandId: '',
        typeId: '',
        productDealId: [],
        manufacturer: '',
        manufacturerDate: null,
        expiryDate: null,
        estimatedDeliveryDate: '',
        sku: '',
        unitsSold: '',
        metricType: '',
        metricValue: '',
        productImage: []
    });
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState({});
    const [error, setError] = useState(null);
    const [brands, setBrands] = useState([]);
    const [types, setTypes] = useState([]);
    const [productCategories, setProductCategories] = useState([]);
    const [homeDeals, setHomeDeals] = useState([]);

    useEffect(() => {
        fetchBrands();
        fetchTypes();
        fetchProductCategories();
        fetchHomeDeals();
    }, []);

    useEffect(() => {
        if (types.length > 0 && brands.length > 0 && productCategories.length > 0 && homeDeals.length > 0) {
            fetchProductDetails();
        }
    }, [types, brands, productCategories, homeDeals]);

    const fetchBrands = async () => {
        try {
            const token = { Authorization: `token ${localStorage.getItem('token')}` };
            const response = await Services.getBrands(token);
            if (response.Status === 1) {
                setBrands(response.brands);
            } else {
                console.error('Error fetching brands:', response.Message);
            }
        } catch (error) {
            console.error('Error fetching brands:', error);
        }
    };

    const fetchTypes = async () => {
        try {
            const token = { Authorization: `token ${localStorage.getItem('token')}` };
            const response = await Services.getTypes(token);
            if (response.Status === 1) {
                setTypes(response.types);
            } else {
                console.error('Error fetching types:', response.Message);
            }
        } catch (error) {
            console.error('Error fetching types:', error);
        }
    };

    const fetchProductCategories = async () => {
        try {
            const token = { Authorization: `token ${localStorage.getItem('token')}` };
            const response = await Services.getCategories(token);
            if (response.Status === 1) {
                setProductCategories(response.productCategories);
            } else {
                console.error('Error fetching productCategories:', response.Message);
            }
        } catch (error) {
            console.error('Error fetching productCategories:', error);
        }
    };

    const fetchHomeDeals = async () => {
        try {
            const token = { Authorization: `token ${localStorage.getItem('token')}` };
            const response = await Services.getDeals(token);
            if (response.Status === 1) {
                setHomeDeals(response.homeDeals);
            } else {
                console.error('Error fetching homeDeals:', response.Message);
            }
        } catch (error) {
            console.error('Error fetching homeDeals:', error);
        }
    };

    const fetchProductDetails = async () => {
        setLoading(true);
        try {
            const token = { Authorization: `token ${localStorage.getItem('token')}` };
            const response = await Services.getProductDetails(productId, token);
            if (response.Status === 1) {
                const product = response.product;
                const brand = brands.find(b => b.brandName === product.brand);
                const type = types.find(t => t.typeName === product.type);
                setProductData({
                    ...product,
                    brandId: brand ? brand.brandId : '',
                    typeId: type ? type.typeId : '',
                    productDealId: product.productDealId,
                    isActive: product.isActive,
                    manufacturerDate: product.manufacturerDate ? dayjs(product.manufacturerDate) : null,
                    expiryDate: product.expiryDate ? dayjs(product.expiryDate) : null,
                    estimatedDeliveryDate: product.estimatedDeliveryDate,
                });
            } else {
                setError('Failed to fetch product details');
            }
        } catch (error) {
            setError('Error fetching product details');
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        let updatedValue = value;

        if (name === "productDealIds") {
            updatedValue = value;
        } else if (name === "discount" || name === "productPrice") {
            updatedValue = parseInt(value, 10);

            if (name === "discount") {
                updatedValue = Math.min(updatedValue, 95);
            }

            if (name === "productPrice" && productData.discount !== undefined) {
                const productPrice = parseInt(value, 10);
                const discount = Math.min(parseInt(productData.discount, 10), 95);
                const productDiscountPrice = productPrice - (productPrice * discount / 100);
                setProductData(prevProductData => ({
                    ...prevProductData,
                    productDiscountPrice: productDiscountPrice.toFixed(2)
                }));
            } else if (name === "discount" && productData.productPrice !== undefined) {
                const productPrice = parseInt(productData.productPrice, 10);
                const discount = Math.min(parseInt(value, 10), 95);
                const productDiscountPrice = productPrice - (productPrice * discount / 100);
                setProductData(prevProductData => ({
                    ...prevProductData,
                    productDiscountPrice: productDiscountPrice.toFixed(2)
                }));
            }
        } else {
            if (name === "brandName" || name === "typeName") {
                const selectedOption = brands.find(brand => brand.brandName === value) ||
                    types.find(type => type.typeName === value);
                updatedValue = selectedOption ? selectedOption.brandName || selectedOption.typeName : "";
            } else if (name === "isActive") {
                updatedValue = value === "Active" ? 1 : 0;
            }
        }

        setProductData(prevProductData => ({
            ...prevProductData,
            [name]: updatedValue
        }));

        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: ''
        }));
    };

    const stripUrlToEndpoint = (url) => {
        const urlParts = url.split('/');
        return urlParts[urlParts.length - 1];
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const updatedProductData = {
            ...productData,
            productImage: productData.productImage.map(img => stripUrlToEndpoint(img))
        };

        try {
            const token = { Authorization: `token ${localStorage.getItem('token')}` };
            const response = await Services.editProduct(productId, JSON.stringify(updatedProductData), token);
            if (response.Status === 1) {
                navigate('/products');
                Success(response.Message);
            } else {
                setError('Failed to update product');
            }
        } catch (error) {
            setError('Error updating product');
        }
    };

    if (loading) {
        return (
            <div className={EditOrderGeneralClasses['spinner-container']}>
                <Hourglass
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="hourglass-loading"
                    colors={['#23232A', '#60E19B']}
                />
            </div>
        );
    }

    if (error) {
        return <Typography>{error}</Typography>;
    }

    const handleDateChange = (name, date) => {
        setProductData(prevProductData => ({
            ...prevProductData,
            [name]: date
        }));

        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: ''
        }));
    };


    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div>
                <form onSubmit={handleSubmit}>
                    <div className={EditProdClasses['main-prod-over-btn']}>
                        <button className={EditProdClasses['update-prod-overview-btn']} type="submit">Save Changes</button>
                    </div>
                    <table className={AddUserClasses['user-table']}>
                        <thead>
                            <tr>
                                <th colSpan="2" className={AddUserClasses['table-header']}>Basic Product Information</th>
                            </tr>
                        </thead>
                        <tbody>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <tr>
                                        <td className={AddUserClasses['table-value']}>
                                            <TextField
                                                style={{ width: '400px' }}
                                                fullWidth
                                                required
                                                id="productName"
                                                name="productName"
                                                value={productData.productName || ''}
                                                onChange={handleChange}
                                                label="Product name"
                                                variant="outlined"
                                                margin="dense"
                                                className={AddUserClasses['table-textfield-all']}
                                                error={!!errors.productName}
                                                helperText={errors.productName}
                                            />
                                        </td>
                                    </tr>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <tr>
                                        <td className={AddUserClasses['table-value']}>
                                            <FormControl fullWidth margin="dense" variant="outlined">
                                                <InputLabel id="brand-label">Brand</InputLabel>
                                                <Select
                                                    style={{ width: '400px' }}
                                                    labelId="brand-label"
                                                    id="brand"
                                                    name="brand"
                                                    value={productData.brand || ''}
                                                    onChange={handleChange}
                                                    label="Brand"
                                                    className={AddUserClasses['table-textfield-all']}
                                                    error={!!errors.brand}
                                                >
                                                    {brands.map((brand) => (
                                                        <MenuItem key={brand.brandId} value={brand.brandName}>
                                                            {brand.brandName}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </td>
                                    </tr>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <tr>
                                        <td className={AddUserClasses['table-value']}>
                                            <FormControl fullWidth margin="dense" variant="outlined">
                                                <InputLabel id="type-label">Type</InputLabel>
                                                <Select
                                                    style={{ width: '400px' }}
                                                    labelId="type-label"
                                                    id="typeId"
                                                    name="typeId"
                                                    value={productData.typeId || ''}
                                                    onChange={handleChange}
                                                    label="Type"
                                                    className={AddUserClasses['table-textfield-all']}
                                                    error={!!errors.typeId}
                                                >
                                                    {types.map((type) => (
                                                        <MenuItem key={type.typeId} value={type.typeId}>
                                                            {type.typeName}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </td>
                                    </tr>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <tr>
                                        <td className={AddUserClasses['table-value']}>
                                            <FormControl fullWidth margin="dense" variant="outlined">
                                                <InputLabel id="productCategoryId-label">Category</InputLabel>
                                                <Select
                                                    style={{ width: '400px' }}
                                                    labelId="productCategoryId-label"
                                                    id="productCategoryId"
                                                    name="productCategoryId"
                                                    value={productData.productCategoryId || ''}
                                                    onChange={handleChange}
                                                    label="Category"
                                                    className={AddUserClasses['table-textfield-all']}
                                                    error={!!errors.productCategoryId}
                                                >
                                                    {productCategories.map((category) => (
                                                        <MenuItem key={category.productCategoryId} value={category.productCategoryId}>
                                                            {category.categoryName}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </td>
                                    </tr>
                                </Grid>
                            </Grid>
                        </tbody>
                    </table>
                    <table className={AddUserClasses['user-table']}>
                        <thead>
                            <tr>
                                <th colSpan="2" className={AddUserClasses['table-header']}>Manufacturing Information</th>
                            </tr>
                        </thead>
                        <tbody>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <tr>
                                        <td className={AddUserClasses['table-value']}>
                                            <TextField
                                                style={{ width: '400px' }}
                                                fullWidth
                                                required
                                                id="manufacturer"
                                                name="manufacturer"
                                                value={productData.manufacturer || ''}
                                                onChange={handleChange}
                                                label="Manufacturer"
                                                variant="outlined"
                                                margin="dense"
                                                className={AddUserClasses['table-textfield-all']}
                                                error={!!errors.manufacturer}
                                                helperText={errors.manufacturer}
                                            />
                                        </td>
                                    </tr>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <tr>
                                        <td className={AddUserClasses['table-value']}>
                                            <DatePicker
                                                label="Manufacturer Date"
                                                style={{ width: '400px' }}
                                                fullWidth
                                                value={productData.manufacturerDate}
                                                onChange={(date) => handleDateChange('manufacturerDate', date)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        required
                                                        variant="outlined"
                                                        margin="dense"
                                                        className={AddUserClasses['table-textfield-all']}
                                                        error={!!errors.manufacturerDate}
                                                        helperText={errors.manufacturerDate}
                                                    />
                                                )}
                                            />
                                        </td>
                                    </tr>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <tr>
                                        <td className={AddUserClasses['table-value']}>
                                            <DatePicker
                                                label="Expiry Date"
                                                style={{ width: '400px' }}
                                                fullWidth
                                                value={productData.expiryDate}
                                                onChange={(date) => handleDateChange('expiryDate', date)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        required
                                                        variant="outlined"
                                                        margin="dense"
                                                        className={AddUserClasses['table-textfield-all']}
                                                        error={!!errors.expiryDate}
                                                        helperText={errors.expiryDate}
                                                    />
                                                )}
                                            />
                                        </td>
                                    </tr>
                                </Grid>
                            </Grid>
                        </tbody>
                    </table>
                    <table className={AddUserClasses['user-table']}>
                        <thead>
                            <tr>
                                <th colSpan="2" className={AddUserClasses['table-header']}>Pricing Information</th>
                            </tr>
                        </thead>
                        <tbody>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <tr>
                                        <td className={AddUserClasses['table-value']}>
                                            <TextField
                                                style={{ width: '400px' }}
                                                fullWidth
                                                required
                                                id="productPrice"
                                                name="productPrice"
                                                value={productData.productPrice || ''}
                                                onChange={handleChange}
                                                label="MRP"
                                                variant="outlined"
                                                margin="dense"
                                                className={AddUserClasses['table-textfield-all']}
                                                error={!!errors.productPrice}
                                                helperText={errors.productPrice}
                                            />
                                        </td>
                                    </tr>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <tr>
                                        <td className={AddUserClasses['table-value']}>
                                            <TextField
                                                style={{ width: '400px' }}
                                                fullWidth
                                                required
                                                id="discount"
                                                name="discount"
                                                value={productData.discount || ''}
                                                onChange={handleChange}
                                                label="Discount"
                                                variant="outlined"
                                                margin="dense"
                                                className={AddUserClasses['table-textfield-all']}
                                                error={!!errors.discount}
                                                helperText={errors.discount}
                                            />
                                        </td>
                                    </tr>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <tr>
                                        <td className={AddUserClasses['table-value']}>
                                            <TextField
                                                style={{ width: '400px' }}
                                                fullWidth
                                                required
                                                id="productDiscountPrice"
                                                name="productDiscountPrice"
                                                value={productData.productDiscountPrice || ''}
                                                onChange={handleChange}
                                                label="Selling Price"
                                                variant="outlined"
                                                margin="dense"
                                                className={AddUserClasses['table-textfield-all']}
                                                error={!!errors.productDiscountPrice}
                                                helperText={errors.productDiscountPrice}
                                            />
                                        </td>
                                    </tr>
                                </Grid>

                                <Grid item xs={12}>
                                    <tr>
                                        <td className={AddUserClasses['table-value']}>
                                            <FormControl fullWidth margin="dense" variant="outlined">
                                                <InputLabel id="deal-label">Deal</InputLabel>
                                                <Select
                                                    style={{ width: '400px' }}
                                                    labelId="deal-label"
                                                    id="productDealId"
                                                    name="productDealId"
                                                    value={productData.productDealId || []}
                                                    onChange={handleChange}
                                                    label="Deal"
                                                    className={AddUserClasses['table-textfield-all']}
                                                    error={!!errors.productDealId}
                                                    multiple
                                                >
                                                    {homeDeals.map((deal) => (
                                                        <MenuItem key={deal.productDealId} value={deal.productDealId}>
                                                            {deal.dealName}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </td>
                                    </tr>
                                </Grid>
                            </Grid>
                        </tbody>
                    </table>
                    <table className={AddUserClasses['user-table']}>
                        <thead>
                            <tr>
                                <th colSpan="2" className={AddUserClasses['table-header']}>Stock and Sales Information</th>
                            </tr>
                        </thead>
                        <tbody>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <tr>
                                        <td className={AddUserClasses['table-value']}>
                                            <TextField
                                                style={{ width: '400px' }}
                                                fullWidth
                                                required
                                                id="stockQty"
                                                name="stockQty"
                                                value={productData.stockQty || ''}
                                                onChange={handleChange}
                                                label="Stock Availability"
                                                variant="outlined"
                                                margin="dense"
                                                className={AddUserClasses['table-textfield-all']}
                                                error={!!errors.stockQty}
                                                helperText={errors.stockQty}
                                            />
                                        </td>
                                    </tr>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <tr>
                                        <td className={AddUserClasses['table-value']}>
                                            <TextField
                                                style={{ width: '400px' }}
                                                fullWidth
                                                required
                                                id="unitsSold"
                                                name="unitsSold"
                                                value={productData.unitsSold || ''}
                                                onChange={handleChange}
                                                label="Units Sold"
                                                variant="outlined"
                                                margin="dense"
                                                className={AddUserClasses['table-textfield-all']}
                                                error={!!errors.unitsSold}
                                                helperText={errors.unitsSold}
                                            />
                                        </td>
                                    </tr>
                                </Grid>
                            </Grid>
                        </tbody>
                    </table>
                    <table className={AddUserClasses['user-table']}>
                        <thead>
                            <tr>
                                <th colSpan="2" className={AddUserClasses['table-header']}>Identification and Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <tr>
                                        <td className={AddUserClasses['table-value']}>
                                            <TextField
                                                style={{ width: '400px' }}
                                                fullWidth
                                                required
                                                id="sku"
                                                name="sku"
                                                value={productData.sku || ''}
                                                onChange={handleChange}
                                                label="SKU"
                                                variant="outlined"
                                                margin="dense"
                                                className={AddUserClasses['table-textfield-all']}
                                                error={!!errors.sku}
                                                helperText={errors.sku}
                                            />
                                        </td>
                                    </tr>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <tr>
                                        <td className={AddUserClasses['table-value']}>
                                            <FormControl fullWidth margin="dense" variant="outlined">
                                                <InputLabel id="isActive-label">Status</InputLabel>
                                                <Select
                                                    style={{ width: '400px' }}
                                                    labelId="isActive-label"
                                                    id="isActive"
                                                    name="isActive"
                                                    value={productData.isActive !== '' ? productData.isActive.toString() : ''}
                                                    onChange={handleChange}
                                                    label="Status"
                                                    className={AddUserClasses['table-textfield-all']}
                                                    error={!!errors.isActive}
                                                >
                                                    <MenuItem value="1">Active</MenuItem>
                                                    <MenuItem value="0">Inactive</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </td>
                                    </tr>
                                </Grid>
                            </Grid>
                        </tbody>
                    </table>
                    <table className={AddUserClasses['user-table']}>
                        <thead>
                            <tr>
                                <th colSpan="2" className={AddUserClasses['table-header']}>Packaging and Delivery Information</th>
                            </tr>
                        </thead>
                        <tbody>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <tr>
                                        <td className={AddUserClasses['table-value']}>
                                            <TextField
                                                style={{ width: '400px' }}
                                                fullWidth
                                                required
                                                id="packageSize"
                                                name="packageSize"
                                                value={productData.packageSize || ''}
                                                onChange={handleChange}
                                                label="Package Size"
                                                variant="outlined"
                                                margin="dense"
                                                className={AddUserClasses['table-textfield-all']}
                                                error={!!errors.packageSize}
                                                helperText={errors.packageSize}
                                            />
                                        </td>
                                    </tr>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <tr>
                                        <td className={AddUserClasses['table-value']}>
                                            <TextField
                                                fullWidth
                                                style={{ width: '400px' }}
                                                required
                                                id="estimatedDeliveryDate"
                                                name="estimatedDeliveryDate"
                                                label="Estimated Delivery Date"
                                                variant="outlined"
                                                margin="dense"
                                                className={AddUserClasses['table-textfield-all']}
                                                value={productData.estimatedDeliveryDate}
                                                onChange={handleChange}
                                                error={!!errors.estimatedDeliveryDate}
                                                helperText={errors.estimatedDeliveryDate}
                                            />
                                        </td>
                                    </tr>
                                </Grid>
                            </Grid>
                        </tbody>
                    </table>
                    <table className={AddUserClasses['user-table']}>
                        <thead>
                            <tr>
                                <th colSpan="2" className={AddUserClasses['table-header']}>Metrics</th>
                            </tr>
                        </thead>
                        <tbody>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <tr>
                                        <td className={AddUserClasses['table-value']}>
                                            <TextField
                                                style={{ width: '400px' }}
                                                fullWidth
                                                required
                                                id="metricType"
                                                name="metricType"
                                                value={productData.metricType || ''}
                                                onChange={handleChange}
                                                label="Metric Type"
                                                variant="outlined"
                                                margin="dense"
                                                className={AddUserClasses['table-textfield-all']}
                                                error={!!errors.metricType}
                                                helperText={errors.metricType}
                                            />
                                        </td>
                                    </tr>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <tr>
                                        <td className={AddUserClasses['table-value']}>
                                            <TextField
                                                style={{ width: '400px' }}
                                                fullWidth
                                                required
                                                id="metricValue"
                                                name="metricValue"
                                                value={productData.metricValue || ''}
                                                onChange={handleChange}
                                                label="Metric Value"
                                                variant="outlined"
                                                margin="dense"
                                                className={AddUserClasses['table-textfield-all']}
                                                error={!!errors.metricValue}
                                                helperText={errors.metricValue}
                                            />
                                        </td>
                                    </tr>
                                </Grid>
                            </Grid>
                        </tbody>
                    </table>

                </form>
            </div>
        </LocalizationProvider>
    );
};

export default EditProductOverview;
