import React, { useState } from 'react';
import { Radio, IconButton, Menu, MenuItem, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Popover, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CommonClasses from '../../../Styles/Common.module.css';
import { Error, Success } from 'Util/Toast'; // Assuming you have a Toast utility for notifications
import { Services } from 'Services'; // Assuming you have a service to fetch user details
import EditUserClasses from './EditUser.module.css';

const AddressList = ({ addresses, defaultAddress, setDefaultAddress, handleEditClick, setAddresses, userId }) => {
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [menuAddressId, setMenuAddressId] = useState(null);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [addressToDelete, setAddressToDelete] = useState(null);
    const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);

    const handleMenuOpen = (event, addressId) => {
        setMenuAnchorEl(event.currentTarget);
        setMenuAddressId(addressId);
    };

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
        setMenuAddressId(null);
    };

    const handleEditMenuItemClick = () => {
        const addressToEdit = addresses.find(addr => addr.addressId === menuAddressId);
        handleEditClick(addressToEdit);
        handleMenuClose();
    };

    const handleDeleteMenuItemClick = () => {
        setAddressToDelete(menuAddressId);
        setIsDeleteDialogOpen(true);
        handleMenuClose();
    };

    const handleDeleteConfirm = () => {
        const token = { Authorization: `Bearer ${localStorage.getItem('token')}` };
        Services.deleteUserAddress(addressToDelete, token)
            .then(response => {
                if (response.Status === 1) {
                    Success('Address deleted successfully');
                    setAddresses(prev => prev.filter(addr => addr.addressId !== addressToDelete));
                } else {
                    Error(response.Message || 'Failed to delete address');
                }
            })
            .catch(error => {
                console.error('Error deleting address', error);
                Error('Error deleting address');
            });
        setIsDeleteDialogOpen(false);
    };

    const handleDeleteDialogClose = () => {
        setIsDeleteDialogOpen(false);
    };

    const handleDefaultChange = (event) => {
        const addressId = parseInt(event.target.value);
        setDefaultAddress(addressId);
        const token = { Authorization: `Bearer ${localStorage.getItem('token')}` };
        Services.makeAddressDefault(userId, addressId, token)
            .then(response => {
                if (response.Status === 1) {
                    Success('Default address updated successfully');
                } else {
                    Error(response.Message || 'Failed to update default address');
                }
            })
            .catch(error => {
                console.error('Error updating default address', error);
                Error('Error updating default address');
            });
    };

    const handlePopoverOpen = (event) => {
        setPopoverAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setPopoverAnchorEl(null);
    };

    const popoverOpen = Boolean(popoverAnchorEl);

    return (
        <div className={CommonClasses['Table']}>
            <table>
                <thead>
                    <tr style={{ borderBottom: '1px solid #CCCCCC' }}>
                        <th style={{ textAlign: 'left', paddingLeft: '20px', backgroundColor: '#FFFFFF' }}>Address</th>
                        <th style={{ textAlign: 'left', paddingLeft: '20px', backgroundColor: '#FFFFFF' }}>Default</th>
                        <th style={{ textAlign: 'left', paddingLeft: '20px', backgroundColor: '#FFFFFF' }}>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {addresses?.map((address, index) => (
                        <tr key={index}>
                            <td>
                                <div>
                                    <p>{address.firstName} {address.lastName}</p>
                                    <p>{address.company}</p>
                                    <p>{address.mobileNumber}</p>
                                    <p>{address.flatNo} {address.deliveryAddress}</p>
                                    <p>{address.area} {address.city}</p>
                                    <p>{address.state} {address.pincode}</p>
                                </div>
                            </td>
                            <td>
                                <Radio
                                    checked={defaultAddress === address.addressId}
                                    onChange={handleDefaultChange}
                                    value={address.addressId}
                                    onMouseEnter={handlePopoverOpen}
                                    onMouseLeave={handlePopoverClose}
                                />
                                <Popover
                                    id="mouse-over-popover"
                                    sx={{
                                        pointerEvents: 'none',
                                    }}
                                    open={popoverOpen}
                                    anchorEl={popoverAnchorEl}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    onClose={handlePopoverClose}
                                    disableRestoreFocus
                                >
                                    <Typography className={EditUserClasses['popover-radio']} sx={{ p: 1 }}>Make default address.</Typography>
                                </Popover>
                            </td>
                            <td>
                                <IconButton onClick={(event) => handleMenuOpen(event, address.addressId)}>
                                    <MoreVertIcon />
                                </IconButton>
                                <Menu
                                    anchorEl={menuAnchorEl}
                                    open={Boolean(menuAnchorEl)}
                                    onClose={handleMenuClose}
                                >
                                    <MenuItem onClick={handleEditMenuItemClick}>Edit</MenuItem>
                                    <MenuItem onClick={handleDeleteMenuItemClick}>Delete</MenuItem>
                                </Menu>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Dialog
                open={isDeleteDialogOpen}
                onClose={handleDeleteDialogClose}
                className={EditUserClasses['del-main-mod']}
            >
                <DialogTitle>Delete Document</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete the file forever? You can't undo this action.
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={EditUserClasses['del-cancel-address-mod']}>
                    <button onClick={handleDeleteDialogClose} className={EditUserClasses['cancel-address-mod']}>
                        Cancel
                    </button>
                    <button onClick={handleDeleteConfirm} className={EditUserClasses['del-address-mod']}>
                        Delete
                    </button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default AddressList;
