import React, { useEffect } from "react";
import LoginClasses from "./login.module.css";
import LoginLogo from "../../Assets/signup.jpeg";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Services } from "../../Services";

function Login() {
    const Navigate = useNavigate();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    useEffect(() => {
        if (localStorage.getItem("token")) {
            let expiry = localStorage.getItem("expiry");
            if (new Date().getTime() > expiry) {
                localStorage.clear();
            } else {
                Navigate("/users");
            }
        }
        // eslint-disable-next-line
    }, []);

    const onSubmit = (data) => {
        const body = JSON.stringify(data);
        Services.Login("POST", body)
            .then((response) => {
                if (response.Status === 1) {
                    // localStorage.setItem(
                    //     "admindata",
                    //     JSON.stringify(response?.admindata)
                    // );
                    localStorage.setItem("token", response?.Token);
                    localStorage.setItem(
                        "AccessItems",
                        JSON.stringify(response?.permissions)
                    );
                    localStorage.setItem(
                        "expiry",
                        new Date().getTime() + 24 * 60 * 60 * 1000
                    );
                    Navigate("/users");
                } else if (response.Status === 0) {
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    return (
        <div className={LoginClasses["login-wrapper"]}>
            <div className={LoginClasses["login-subwrapper"]}>
                <img src={LoginLogo} alt="login-logo" />

                <div className={LoginClasses["login-form"]}>
                    <h3>Login</h3>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={LoginClasses["form-control"]}>
                            <label htmlFor="email">Email</label>
                            <input
                                type="email"
                                {...register("email", {
                                    required: "Email is required!",
                                })}
                            />
                            {errors?.email && (
                                <p className={LoginClasses.error}>
                                    {errors?.email?.message}
                                </p>
                            )}
                        </div>
                        <div className={LoginClasses["form-control"]}>
                            <label htmlFor="password">Password</label>

                            <input
                                type="password"
                                {...register("password", {
                                    required: "Password is required!",
                                })}
                            />
                            {errors?.password && (
                                <p className={LoginClasses.error}>
                                    {errors?.password?.message}
                                </p>
                            )}
                        </div>
                        <input type="submit" value="Log in" />
                        <span className={LoginClasses.border}></span>
                    </form>

                    {/* <div className={LoginClasses["login-links"]}>
                        <NavLink to="/forgotpassword">
                            forgot your Password?
                        </NavLink>
                        <NavLink to="/createaccount">
                            Create Your Account
                        </NavLink>
                    </div> */}
                </div>
            </div>
        </div>
    );
}

export default Login;
