// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Layout_Wrapper__DVjhj {\n    display: flex;\n    /* grid-template-columns: 230px 1fr; */\n}\n.Layout_main-content__3OR9J {\n    /* display: flex; */\n    flex-direction: column;\n    height: 100%;\n    gap: 2rem;\n    background: white;\n    min-height: 100vh;\n    z-index: unset;\n    height: 100%;\n    width: 100%;\n    margin-left: 230px;\n    transition: margin-left 0.3s ease;\n}\n.Layout_nav-hide__VZQqp {\n    margin-left: 70px !important;\n}\n@media screen and (max-width: 900px) {\n    .Layout_Wrapper__DVjhj {\n        grid-template-columns: 70px 1fr !important;\n    }\n    .Layout_main-content__3OR9J {\n        z-index: 1;\n        margin-left: 70px;\n    }\n    /* .nav-hide {\n        margin-left: 0 !important;\n    } */\n}\n", "",{"version":3,"sources":["webpack://./src/Components/Layout/Layout.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sCAAsC;AAC1C;AACA;IACI,mBAAmB;IACnB,sBAAsB;IACtB,YAAY;IACZ,SAAS;IACT,iBAAiB;IACjB,iBAAiB;IACjB,cAAc;IACd,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,iCAAiC;AACrC;AACA;IACI,4BAA4B;AAChC;AACA;IACI;QACI,0CAA0C;IAC9C;IACA;QACI,UAAU;QACV,iBAAiB;IACrB;IACA;;OAEG;AACP","sourcesContent":[".Wrapper {\n    display: flex;\n    /* grid-template-columns: 230px 1fr; */\n}\n.main-content {\n    /* display: flex; */\n    flex-direction: column;\n    height: 100%;\n    gap: 2rem;\n    background: white;\n    min-height: 100vh;\n    z-index: unset;\n    height: 100%;\n    width: 100%;\n    margin-left: 230px;\n    transition: margin-left 0.3s ease;\n}\n.nav-hide {\n    margin-left: 70px !important;\n}\n@media screen and (max-width: 900px) {\n    .Wrapper {\n        grid-template-columns: 70px 1fr !important;\n    }\n    .main-content {\n        z-index: 1;\n        margin-left: 70px;\n    }\n    /* .nav-hide {\n        margin-left: 0 !important;\n    } */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Wrapper": "Layout_Wrapper__DVjhj",
	"main-content": "Layout_main-content__3OR9J",
	"nav-hide": "Layout_nav-hide__VZQqp"
};
export default ___CSS_LOADER_EXPORT___;
