import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Button, TextField, MenuItem, Tabs, Tab, Paper, Popover, Select } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import { TiExport } from 'react-icons/ti';
import { BiPlus } from 'react-icons/bi';
import * as XLSX from 'xlsx';
import { Services } from 'Services';
import CommonClasses from '../../../Styles/Common.module.css';
import ViewUserClasses from '../viewUsers/ViewUsers.module.css';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import moment from 'moment';
import { Success } from 'Util/Toast';

const Container = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(0),
}));

const Header = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
    marginBottom: '22px',
}));

const SearchField = styled(TextField)(({ theme }) => ({
    marginRight: theme.spacing(2),
    flex: 0.5,
    backgroundColor: '#eaecf2',
}));

const UserOrders = () => {
    const { userId } = useParams();
    const [activeTab, setActiveTab] = useState(0);
    const [orders, setOrders] = useState([]);
    const [filteredOrders, setFilteredOrders] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [exportAnchorEl, setExportAnchorEl] = useState(null); // State for Popover anchor
    const [exportFormat, setExportFormat] = useState('');
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [paymentStatus, setPaymentStatus] = useState({});
    const [orderStatus, setOrderStatus] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        fetchOrdersByStatus(activeTab);
    }, [activeTab, userId]);

    useEffect(() => {
        setFilteredOrders(
            orders.filter(order =>
                order.orderId.toString().includes(searchQuery) ||
                getOrderDeliveryStatus(order.orderDeliveryStatus).toLowerCase().includes(searchQuery.toLowerCase())
            )
        );
    }, [searchQuery, orders]);

    const fetchOrdersByStatus = async (status) => {
        const token = { Authorization: `Bearer ${localStorage.getItem('token')}` };
        let orderStatusValue = 0;

        switch (status) {
            case 1:
                orderStatusValue = 1; // Processing
                break;
            case 2:
                orderStatusValue = 2; // Shipped
                break;
            case 3:
                orderStatusValue = 3; // Delivered
                break;
            case 4:
                orderStatusValue = 4; // Cancelled
                break;
            default:
                orderStatusValue = 0; // All Orders
                break;
        }

        try {
            const response = await Services.getUserOrdersByStatus(userId, orderStatusValue, token);
            const ordersWithIds = response.orders.map(order => ({ ...order, id: order.orderId }));
            setOrders(ordersWithIds);
            setFilteredOrders(ordersWithIds);
        } catch (error) {
            console.error('Error fetching orders:', error);
            setOrders([]);
            setFilteredOrders([]);
        }
    };

    const handleExportClick = (event) => {
        setExportAnchorEl(event.currentTarget);
    };

    const handleExportClose = () => {
        setExportAnchorEl(null);
        // Reset export details when closing popover
        setExportFormat('');
        setFromDate(null);
        setToDate(null);
    };

    const handleExport = () => {
        const filteredOrders = orders.filter(order => {
            const orderDate = dayjs(order.createdAt);
            return (!fromDate || orderDate.isAfter(dayjs(fromDate).subtract(1, 'day'))) && (!toDate || orderDate.isBefore(dayjs(toDate).add(1, 'day')));
        });

        if (exportFormat === 'xlsx') {
            exportToXLSX(filteredOrders);
        } else if (exportFormat === 'csv') {
            exportToCSV(filteredOrders);
        }
        handleExportClose();
    };

    const exportToXLSX = (filteredOrders) => {
        const worksheet = XLSX.utils.json_to_sheet(filteredOrders);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Orders');
        XLSX.writeFile(workbook, 'orders.xlsx');
    };

    const exportToCSV = (filteredOrders) => {
        const csvRows = [];
        const headers = ['Order Id', 'Order Details', 'Delivery Date', 'Order Delivery Status', 'Payment'];
        csvRows.push(headers.join(','));

        for (const order of filteredOrders) {
            const row = [
                order.orderId,
                order.products.map(product => product.productName).join(', '),
                order.expectedDelivery,
                getOrderDeliveryStatus(order.orderDeliveryStatus),
                getPaymentType(order.paymentType),
            ];
            csvRows.push(row.join(','));
        }

        const csvData = new Blob([csvRows.join('\n')], { type: 'text/csv' });
        const csvUrl = window.URL.createObjectURL(csvData);
        const link = document.createElement('a');
        link.href = csvUrl;
        link.setAttribute('download', 'orders.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const getOrderDeliveryStatus = (status) => {
        switch (status) {
            case 1:
                return 'Processing';
            case 2:
                return 'Shipped';
            case 3:
                return 'Delivered';
            case 4:
                return 'Cancelled';
            default:
                return 'Unknown';
        }
    };

    const getPaymentType = (type) => {
        switch (type) {
            case 1:
                return 'Cash';
            case 2:
                return 'Online';
            default:
                return 'Unknown';
        }
    };

    const handlePaymentStatusChange = async (event, orderId) => {
        const newPaymentStatus = event.target.value;
        setPaymentStatus({ ...paymentStatus, [orderId]: newPaymentStatus });

        const token = { Authorization: `Bearer ${localStorage.getItem('token')}` };
        try {
            await Services.updatePaymentStatus(orderId, newPaymentStatus, token);
            Success('Payment status updated successfully');
        } catch (error) {
            console.error('Error updating payment status:', error);
        }
    };

    const handleOrderStatusChange = async (event, orderId) => {
        const newStatus = event.target.value;
        setOrderStatus({ ...orderStatus, [orderId]: newStatus });

        const token = { Authorization: `Bearer ${localStorage.getItem('token')}` };
        try {
            await Services.updateOrderDeliveryStatus(orderId, newStatus, token);
            Success('Order status updated successfully');
        } catch (error) {
            console.error('Error updating order status:', error);
        }
    };

    const statusColors = {
        1: '#f3f3f3', // Processing - Light Blue
        2: '#feffb9', // Shipped - Blue
        3: '#79f4a3', // Delivered - Green
        4: '#ffa7a7', // Cancelled - Red
    };

    const columns = [
        { field: 'orderId', headerName: 'Order Id', width: 200 },
        { field: 'createdAt', headerName: 'Ordered Date', width: 200, valueFormatter: (params) => moment(params).format('DD MMM YYYY') },
        { field: 'expectedDelivery', headerName: 'Delivery Date', width: 200, valueFormatter: (params) => moment(params).format('DD MMM YYYY') },
        { field: 'orderDetails', headerName: 'Order Details', width: 200, renderCell: (params) => params.row.products.map(product => product.productName).join(', ') },
        {
            field: 'orderStatus',
            headerName: 'Order Status',
            width: 220,
            renderCell: (params) => {
                const isDisabled = params.row.orderStatus === 3 || params.row.orderStatus === 4; // Disable for Delivered or Cancelled
                return (
                    <Select
                        value={orderStatus[params.row.orderId] || params.value}
                        onChange={(event) => handleOrderStatusChange(event, params.row.orderId)}
                        style={{
                            width: '175px',
                            height: '30px',
                            backgroundColor: statusColors[orderStatus[params.row.orderId] || params.value],
                            color: 'black',
                        }}
                        disabled={isDisabled} // Disable the Select if the order status is Cancelled or Delivered
                    >
                        <MenuItem value={1}>Processing</MenuItem>
                        <MenuItem value={2}>Shipped</MenuItem>
                        <MenuItem value={3}>Delivered</MenuItem>
                        <MenuItem value={4}>Cancelled</MenuItem>
                    </Select>
                );
            },
        },
        {
            field: 'paymentStatus',
            headerName: 'Payment Status',
            width: 250,
            renderCell: (params) => (
                <Select
                    value={paymentStatus[params.row.orderId] || params.value}
                    onChange={(event) => handlePaymentStatusChange(event, params.row.orderId)}
                    style={{ width: '220px', height: '30px', backgroundColor: 'white', border: 'none' }}
                >
                    <MenuItem value={2}>Paid</MenuItem>
                    <MenuItem value={1}>Unpaid</MenuItem>
                </Select>
            ),
        },
    ];

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Container>
                <Header>
                    <SearchField
                        variant="outlined"
                        placeholder="Search for Order ID/Order Status"
                        InputProps={{
                            startAdornment: <SearchIcon />
                        }}
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <div className={CommonClasses['control-btns']} style={{ marginLeft: '400px', position: 'absolute' }}>
                        <Button
                            className={CommonClasses['button-for-add']}
                            onClick={() => navigate("/addorder")}
                        >
                            <BiPlus size={20} /> Add Order
                        </Button>
                    </div>
                    <Button className={ViewUserClasses['exp-main-btn']} onClick={handleExportClick}>
                        <TiExport className={ViewUserClasses['exp-main-sub-btn']} /> Export
                    </Button>
                    <Popover
                        open={Boolean(exportAnchorEl)}
                        anchorEl={exportAnchorEl}
                        onClose={handleExportClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <div style={{ padding: '20px', width: '400px' }}>
                            <TextField
                                select
                                label="Format"
                                value={exportFormat}
                                onChange={(e) => setExportFormat(e.target.value)}
                                margin="normal"
                                style={{ paddingBottom: '10px', width: '397px' }}
                            >
                                <MenuItem value="xlsx">.xlsx</MenuItem>
                                <MenuItem value="csv">.csv</MenuItem>
                            </TextField>
                            <div className={ViewUserClasses['export-date-fields']}>
                                <DatePicker
                                    label="From Date"
                                    value={fromDate}
                                    onChange={(date) => setFromDate(date)}
                                    renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
                                />
                                <DatePicker
                                    label="To Date"
                                    value={toDate}
                                    onChange={(date) => setToDate(date)}
                                    renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
                                />
                            </div>
                            <div className={ViewUserClasses['inside-rightname']} style={{ width: '100%' }}>
                                <button className={ViewUserClasses['new-invoice-btn']} onClick={handleExport}>Export</button>
                            </div>
                        </div>
                    </Popover>
                </Header>
                <Paper>
                    <Tabs value={activeTab} onChange={(e, newValue) => setActiveTab(newValue)} aria-label="Order Tabs">
                        <Tab label="All Orders" value={0} />
                        <Tab label="Processing" value={1} />
                        <Tab label="Shipped" value={2} />
                        <Tab label="Delivered" value={3} />
                        <Tab label="Cancelled" value={4} />
                    </Tabs>
                </Paper>
                <div className={CommonClasses['Table']} style={{ maxHeight: 600 }}>
                    <DataGrid
                        rows={filteredOrders}
                        columns={columns}
                        pageSize={pageSize}
                        rowsPerPageOptions={[10, 25, 50, { value: -1, label: 'All' }]}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        pagination
                        getRowId={(row) => row.orderId}
                    />
                </div>
            </Container>
        </LocalizationProvider>
    );
};

export default UserOrders;
