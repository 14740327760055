import Layout from 'Components/Layout';
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import CommonClasses from 'Styles/Common.module.css';
import ProductClasses from '../Product/Products.module.css';
import { useNavigate } from 'react-router-dom';
import { Services } from 'Services';
import SkeletonLoad from "Components/TableLoad/load";
import { BiPlus } from 'react-icons/bi';
import FaqClasses from '../faq/Faq.module.css'
import { FaRegEdit } from 'react-icons/fa';
import { Box, Button, Grid, MenuItem, Select, TextField, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { Success } from 'Util/Toast';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AiFillEdit } from "react-icons/ai";
import { AiFillDelete } from "react-icons/ai";

const Faq = () => {
    const Navigate = useNavigate();
    const [faqs, setFaqs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [deleteIndex, setDeleteIndex] = useState(null);

    useEffect(() => {
        const fetchFaqs = async () => {
            try {
                const token = { Authorization: `token ${localStorage.getItem('token')}` };
                const response = await Services.getFAQs(token);
                if (response.Status === 1) {
                    setFaqs(response.FAQs);
                }
                setLoading(false);
            } catch (error) {
                console.error('Error fetching FAQs:', error);
                setLoading(false);
            }
        };

        fetchFaqs();
    }, []);

    const handleDeleteFAQ = async (index) => {
        try {
            const faqToDelete = faqs[index];
            const token = { Authorization: `token ${localStorage.getItem('token')}` };
            const response = await Services.deleteFAQ(faqToDelete.customerSupportId, token);
            console.log('FAQ deleted successfully:', response);
            // Remove the deleted FAQ from the list
            const updatedFaqs = [...faqs];
            updatedFaqs.splice(index, 1);
            setFaqs(updatedFaqs);
            Success(response.Message);
        } catch (error) {
            console.error('Error deleting FAQ:', error);
        }
    };

    const handleUpdateFAQ = (customerSupportId) => {
        Navigate(`/edit-faq/${customerSupportId}`);
    };

    return (
        <Layout Active={'FAQs'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>FAQs</title>
            </Helmet>
            <div className={CommonClasses['Container']}>
            <h3 className={ProductClasses['main-header']}>FAQs<span className={ProductClasses['count-of-users']}>({faqs.length})</span></h3>
                <div className={CommonClasses['control-btns']}>
                    <Button style={{marginBottom: '20px'}}
                        className={CommonClasses['button-for-add']}
                        onClick={() => {
                            Navigate("/addfaq");
                        }}>
                        <BiPlus size={20} /> Create FAQ
                    </Button>
                </div>
                    {loading ? (
                        <SkeletonLoad TableHead={[]} />
                    ) : (
                        <div>
                            {faqs.map((faqItem, index) => (
                                <Accordion key={index} className={FaqClasses['main-accordian']}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls={`panel${index}-content`}
                                        id={`panel${index}-header`}
                                        className={FaqClasses['accordian-question']}
                                    >
                                        <Typography>{faqItem.question}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>{faqItem.answer}</Typography>
                                    </AccordionDetails>
                                    <div className={ProductClasses['main-edit-del']}>
                                        <button className={ProductClasses['edit-btn']} onClick={() => handleUpdateFAQ(faqItem.customerSupportId)}>EDIT</button>
                                        <button className={ProductClasses['del-btn']}
                                            color='error'
                                            onClick={() => {
                                                if (window.confirm('Are you sure you want to delete this FAQ?')) {
                                                    handleDeleteFAQ(index);
                                                }
                                            }}>
                                            DELETE
                                        </button>
                                    </div>
                                </Accordion>
                            ))}
                        </div>
                    )}
                </div>
        </Layout>
    );
};

export default Faq;
