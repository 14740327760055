import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ViewUserClasses from '../../users/viewUsers/ViewUsers.module.css';
import { Services } from 'Services';
import { Skeleton } from '@mui/material';
import moment from 'moment';

const ProductOverview = () => {
    const { productId } = useParams();
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const token = { Authorization: `Bearer ${localStorage.getItem('token')}` };
                const response = await Services.getProductDetails(productId, token);
                setProduct(response.product); // Adjust this line based on your API response structure
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchUserDetails();
    }, [productId]);

    if (loading) {
        // Show skeleton loading state while fetching data
        return (
            <table className={ViewUserClasses['user-table']}>
                <thead>
                    <tr>
                        <th colSpan="2" className={ViewUserClasses['table-header']}>Basic Details</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colSpan="2">
                            <div style={{padding: '5px'}} className={ViewUserClasses['skeleton-viewuseroverview']}>
                                <Skeleton variant="rectangular" width={300} height={30} />
                                <Skeleton variant="rectangular" width={640} height={30} />
                                <Skeleton variant="rectangular" width={300} height={30} />
                                <Skeleton variant="rectangular" width={640} height={30} />
                                <Skeleton variant="rectangular" width={300} height={30} />
                                <Skeleton variant="rectangular" width={640} height={30} />
                                <Skeleton variant="rectangular" width={300} height={30} />
                                <Skeleton variant="rectangular" width={640} height={30} />
                                <Skeleton variant="rectangular" width={300} height={30} />
                                <Skeleton variant="rectangular" width={640} height={30} />
                                <Skeleton variant="rectangular" width={300} height={30} />
                                <Skeleton variant="rectangular" width={640} height={30} />
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!product) {
        return <div>No user found.</div>;
    }

    const formatDate = (dateString) => {
        return moment(dateString).format('DD MMM YYYY');
    };

    return (
        <table className={ViewUserClasses['user-table']}>
            <thead>
                <tr>
                    <th colSpan="2" className={ViewUserClasses['table-header']}>Basic Details</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td className={ViewUserClasses['table-label']}>Name</td>
                    <td className={ViewUserClasses['table-value']}>{product.productName}{' '}{product.metricValue}{product.metricType}</td>
                </tr>
                <tr>
                    <td className={ViewUserClasses['table-label']}>SKU</td>
                    <td className={ViewUserClasses['table-value']}>{product.sku}</td>
                </tr>
                <tr>
                    <td className={ViewUserClasses['table-label']}>Date Created</td>
                    <td className={ViewUserClasses['table-value']}>{formatDate(product.createdAt)}</td>
                </tr>
                <tr>
                    <td className={ViewUserClasses['table-label']}>Category</td>
                    <td className={ViewUserClasses['table-value']}>{product.categoryName}</td>
                </tr>
                <tr>
                    <td className={ViewUserClasses['table-label']}>Stock Availability</td>
                    <td className={ViewUserClasses['table-value']}>{product.stockQty}</td>
                </tr>
                <tr>
                    <td className={ViewUserClasses['table-label']}>Selling Price</td>
                    <td className={ViewUserClasses['table-value']}>{product.productDiscountPrice}</td>
                </tr>
                <tr>
                    <td className={ViewUserClasses['table-label']}>Pack Size</td>
                    <td className={ViewUserClasses['table-value']}>{product.packageSize}</td>
                </tr>
                <tr>
                    <td className={ViewUserClasses['table-label']}>MRP</td>
                    <td className={ViewUserClasses['table-value']}>{product.productPrice}</td>
                </tr>
                <tr>
                    <td className={ViewUserClasses['table-label']}>Manufacturer</td>
                    <td className={ViewUserClasses['table-value']}>{product.manufacturer}</td>
                </tr>
                <tr>
                    <td className={ViewUserClasses['table-label']}>Manufacturing Date</td>
                    <td className={ViewUserClasses['table-value']}>{formatDate(product.manufacturerDate)}</td>
                </tr>
                <tr>
                    <td className={ViewUserClasses['table-label']}>Expiry Date</td>
                    <td className={ViewUserClasses['table-value']}>{formatDate(product.expiryDate)}</td>
                </tr>
            </tbody>
        </table>
    );
};

export default ProductOverview;
