import Layout from 'Components/Layout';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Services } from 'Services';
import { Helmet } from 'react-helmet';
import CommonClasses from 'Styles/Common.module.css';
import { useNavigate } from 'react-router-dom';
import TutorClasses from '../Categories/addCategory/index.module.css';
import ReactLoading from 'react-loading';
import { BsCloudUpload } from 'react-icons/bs';
import S3 from 'react-s3';
import { Success } from 'Util/Toast';
import CouponClasses from './Coupon.module.css';
import ProductClasses from '../Product/Products.module.css';
import { Breadcrumbs, Button, Link, Stack, Typography, MenuItem, Select } from '@mui/material';
import ViewUserClasses from '../users/viewUsers/ViewUsers.module.css';
import AddUserClasses from '../users/addUser/AddUser.module.css';
import { MdOutlineArrowBackIos } from 'react-icons/md';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
window.Buffer = window.Buffer || require('buffer').Buffer;

const EditCoupon = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [image, setImage] = useState('');
    const { couponCodeID } = useParams();
    const [coupon, setCoupon] = useState(null);
    const [startDateLabel, setStartDateLabel] = useState('');
    const [endDateLabel, setEndDateLabel] = useState('');
    const [imagePreview, setImagePreview] = useState('');
    const [couponType, setCouponType] = useState('');

    const config = {
        bucketName: process.env.REACT_APP_BUCKETNAME,
        dirName: 'coupons',
        region: 'ap-south-1',
        accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
    };

    useEffect(() => {
        const fetchCoupon = async () => {
            try {
                const token = { Authorization: `token ${localStorage.getItem('token')}` };
                const response = await Services.getCouponById(couponCodeID, token);
                if (response.Status === 1) {
                    setCoupon(response.coupon);
                    setStartDateLabel(new Date(response.coupon.startDate).toISOString().split('T')[0]);
                    setEndDateLabel(new Date(response.coupon.endDate).toISOString().split('T')[0]);
                    setImagePreview(response.coupon.image);
                    setCouponType(response.coupon.type === 1 ? 'percentage' : 'money');
                }
            } catch (error) {
                console.error('Error fetching coupon:', error);
            }
        };

        fetchCoupon();
    }, [couponCodeID]);

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        try {
            const form = e.target;

            // Check if any values have been changed
            const updatedCouponData = {};
            Object.keys(coupon).forEach((key) => {
                if (form[key] && form[key].value !== coupon[key]) {
                    updatedCouponData[key] = form[key].value;
                }
            });

            // Check if image has been changed
            if (!image && coupon.image) {
                const filename = coupon.image.split('/').pop();
                updatedCouponData.image = filename;
            } else if (image) {
                const filename = image.split('/').pop();
                updatedCouponData.image = filename;
            }

            // Update start date and end date from labels
            const startDate = new Date(startDateLabel).toISOString();
            const endDate = new Date(endDateLabel).toISOString();

            updatedCouponData.startDate = startDate;
            updatedCouponData.endDate = endDate;

            // Update coupon type
            updatedCouponData.type = couponType === 'percentage' ? 1 : 2;

            if (Object.keys(updatedCouponData).length > 0) {
                const token = { Authorization: `token ${localStorage.getItem('token')}` };
                const response = await Services.updateCoupon(couponCodeID, updatedCouponData, token);

                if (response.Status === 1) {
                    setCoupon((prevCoupon) => ({
                        ...prevCoupon,
                        ...updatedCouponData,
                    }));
                    navigate('/coupons');
                    Success(response.Message);
                } else {
                    console.error('Failed to update coupon:', response.Message);
                }
            } else {
                console.log('No changes detected.');
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    const handleStartDateChange = (e) => {
        const startDate = e.target.value;
        setStartDateLabel(startDate);
    };

    const handleEndDateChange = (e) => {
        const endDate = e.target.value;
        setEndDateLabel(endDate);
    };

    const filehandleChange = (event) => {
        const files = event.target.files[0];
        if (
            files.type === 'image/png' ||
            files.type === 'image/jpeg' ||
            files.type === 'image/webp'
        ) {
            const timestamp = Date.now();
            const newfile = new File(
                [files],
                `${timestamp}.${String(files.type).split('/')[1]}`
            );

            S3.uploadFile(newfile, {
                ...config,
                dirName: 'coupons',
            })
                .then((res) => {
                    const imagePath = res.location;
                    setImagePreview(URL.createObjectURL(files));
                    setImage(imagePath);
                })
                .catch((err) => {
                    alert(err);
                    console.log(err);
                });
        } else {
            alert(`${files.name} is neither PNG nor jpeg`);
        }
    };

    const handleMaxDiscountChange = (event) => {
        const value = parseFloat(event.target.value);
        if (value > 95) {
            event.target.value = 95;
        }
    };

    if (!coupon) {
        return <div>Loading...</div>;
    }

    const fileuploadDesign = (
        <div style={{ border: 'none' }} className={TutorClasses['add-item']} onClick={() => document.getElementById("image").click()}>
            <div className={TutorClasses['upload-image']}>
                {imagePreview && <img src={imagePreview} alt="Selected" className={ViewUserClasses['uploaded-banner-image']} />}
                {!imagePreview && (
                    <div className={TutorClasses['add-item']} style={{ border: 'none' }}>
                        <BsCloudUpload className={TutorClasses['upload-icon']} />
                        <h5>Drag Your Files here</h5>
                        <p>(Only *.jpeg, *.png will be accepted)</p>
                    </div>
                )}
                <input type="file" id="image" onChange={filehandleChange} style={{ display: 'none' }} />
            </div>
        </div>
    );

    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" onClick={() => navigate('/')}>
            Home
        </Link>,
        <Link underline="hover" key="2" color="inherit" onClick={() => navigate('/coupons')}>
            Coupons
        </Link>,
        <Typography key="3" color="text.primary">
            Update Coupon
        </Typography>,
    ];

    return (
        <Layout Active={'Coupons'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Edit Coupon</title>
            </Helmet>
            <div className={CommonClasses['Container']}>
                <div className={AddUserClasses['new-main-div']} style={{ paddingLeft: '20px' }}>
                    <div className={ViewUserClasses['main-on-ctrl-btns']}>
                        <div className={CommonClasses['control-btns']}>
                            <Button className={CommonClasses["button-for-add"]} onClick={() => navigate(-1)}><MdOutlineArrowBackIos />Back</Button>
                        </div>
                        <Stack spacing={2}>
                            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                                {breadcrumbs}
                            </Breadcrumbs>
                        </Stack>
                    </div>
                </div>

                <div style={{ boxShadow: 'none' }} className={TutorClasses['wrapper']} data-aos="zoom-in-down">
                    <h2 style={{ paddingLeft: '7px' }} className={ProductClasses['main-header']}>Edit Coupon</h2>
                    <form className={CouponClasses["form"]} onSubmit={handleFormSubmit}>
                        <div className={CouponClasses['form-control']}>
                            <label htmlFor="couponCode">Coupon Code</label>
                            <input className={CouponClasses["input"]} type="text" name="couponCode" defaultValue={coupon.couponCode} />
                        </div>

                        <div className={CouponClasses['form-control']}>
                            <label htmlFor="type">Type</label>
                            <Select style={{ width: '280px', height: '40px' }}
                                value={couponType}
                                onChange={(e) => setCouponType(e.target.value)}
                                displayEmpty
                                inputProps={{ name: 'type' }}
                            >
                                <MenuItem value={'percentage'}>Percentage</MenuItem>
                                <MenuItem value={'money'}>Money</MenuItem>
                            </Select>
                        </div>

                        <div className={CouponClasses['form-control']}>
                            <label htmlFor="offerValue">Offer Value</label>
                            <input className={CouponClasses["input"]} type="number" step="0.01" name="offerValue" defaultValue={coupon.offerValue} />
                        </div>

                        <div className={CouponClasses['form-control']}>
                            <label htmlFor="maxDiscount">Max Discount(%)</label>
                            <input className={CouponClasses["input"]} type="number" name="maxDiscount" defaultValue={coupon.maxDiscount} onChange={handleMaxDiscountChange} />
                        </div>

                        <div className={CouponClasses['form-control']}>
                            <label htmlFor="startDate">Start Date</label>
                            <input className={CouponClasses["input"]} type="date" name="startDate" value={startDateLabel} onChange={handleStartDateChange} />
                        </div>

                        <div className={CouponClasses['form-control']}>
                            <label htmlFor="endDate">End Date</label>
                            <input className={CouponClasses["input"]} type="date" name="endDate" value={endDateLabel} onChange={handleEndDateChange} />
                        </div>

                        <div className={CouponClasses['form-control']}>
                            <label htmlFor="description">Description</label>
                            <textarea className={CouponClasses["input"]} name="description" defaultValue={coupon.description}></textarea>
                        </div>

                        <div className={TutorClasses['file-upload-wrapper']}>
                            <header>
                                <h3>
                                    Upload Image <span className="important">*</span>
                                </h3>
                            </header>
                            <div className={TutorClasses["upload-image"]}>
                                {fileuploadDesign}
                            </div>
                        </div>

                        <button className={CouponClasses['coup-btn']} disabled={loading}>
                            {loading ? (
                                <ReactLoading color="green" type="spokes" height={30} width={30} />
                            ) : (
                                'Submit'
                            )}
                        </button>
                    </form>
                </div>
            </div>
        </Layout>
    );
};

export default EditCoupon;
