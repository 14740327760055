import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Grid } from '@mui/material';
import { BiPlus } from 'react-icons/bi';
import { useParams } from 'react-router-dom';
import { Error, Success } from 'Util/Toast'; // Assuming you have a Toast utility for notifications
import AddressList from './AddressList';
import AddressForm from './AddressForm';
import { Services } from 'Services'; // Assuming you have a service to fetch user details
import AddUserClasses from '../addUser/AddUser.module.css';
import CommonClasses from '../../../Styles/Common.module.css';
import { Hourglass } from 'react-loader-spinner';
import EditOrderGeneralClasses from '../../orders/editOrder/EditOrder.module.css'


const EditUserAddress = () => {
    const { userId } = useParams();
    const [open, setOpen] = useState(false);
    const [defaultAddress, setDefaultAddress] = useState(null);
    const [addresses, setAddresses] = useState([]);
    const [formValues, setFormValues] = useState({
        firstName: '',
        lastName: '',
        company: '',
        mobileNumber: '',
        flatNo: '',
        deliveryAddress: '',
        area: '',
        city: '',
        state: '',
        pincode: '',
    });
    const [editMode, setEditMode] = useState(false);
    const [currentAddressId, setCurrentAddressId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

        const fetchUserData = async () => {
            try {
                await delay(2000); // Simulate loading delay
                const token = { Authorization: `Bearer ${localStorage.getItem('token')}` };
                const response = await Services.getUserDetails(userId, token);
                if (response.Status === 1) {
                    setAddresses(response.addresses);
                    const defaultAddressObj = response.addresses.find(addr => addr.addressType === 1);
                    setDefaultAddress(defaultAddressObj ? defaultAddressObj.addressId : null);
                } else {
                    setError(response.Message || 'Failed to fetch user details');
                }
            } catch (error) {
                console.error('Error fetching user data', error);
                setError('Error fetching user data');
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, [userId]);

    const handleClickOpen = () => {
        setOpen(true);
        setEditMode(false);
        setCurrentAddressId(null);
        setFormValues({
            firstName: '',
            lastName: '',
            company: '',
            mobileNumber: '',
            flatNo: '',
            deliveryAddress: '',
            area: '',
            city: '',
            state: '',
            pincode: '',
        });
    };

    const handleEditClick = (address) => {
        setOpen(true);
        setEditMode(true);
        setCurrentAddressId(address.addressId);
        setFormValues({
            firstName: address.firstName,
            lastName: address.lastName,
            company: address.company,
            mobileNumber: address.mobileNumber,
            flatNo: address.flatNo,
            deliveryAddress: address.deliveryAddress,
            area: address.area,
            city: address.city,
            state: address.state,
            pincode: address.pincode,
        });
    };

    const handleClose = () => {
        setOpen(false);
        setFormValues({
            firstName: '',
            lastName: '',
            company: '',
            mobileNumber: '',
            flatNo: '',
            deliveryAddress: '',
            area: '',
            city: '',
            state: '',
            pincode: '',
        });
    };

    const handleSave = () => {
        const token = { Authorization: `Bearer ${localStorage.getItem('token')}` };
        if (editMode) {
            const updatedAddresses = addresses.map(address =>
                address.addressId === currentAddressId
                    ? { ...address, ...formValues }
                    : address
            );
            const requestBody = { addresses: updatedAddresses, userId: parseInt(userId) };
            Services.updateUser(userId, requestBody, token)
                .then(response => {
                    if (response.Status === 1) {
                        Success('User updated successfully');
                        setAddresses(updatedAddresses);
                        handleClose();
                    } else {
                        Error(response.Message || 'Failed to update user');
                    }
                })
                .catch(error => {
                    console.error('Error updating user', error);
                    Error('Error updating user');
                });
        } else {
            const requestBody = { ...formValues, userId: parseInt(userId) };
            Services.addUserAddress(requestBody, token)
                .then(response => {
                    if (response.Status === 1) {
                        Success('Address added successfully');
                        setAddresses(response.addresses);
                        handleClose();
                    } else {
                        Error(response.Message || 'Failed to add address');
                    }
                })
                .catch(error => {
                    console.error('Error adding address', error);
                    Error('Error adding address');
                });
        }
    };

    if (loading) {
        return (
            <div className={EditOrderGeneralClasses['spinner-container']}>
                <Hourglass
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="hourglass-loading"
                    colors={['#23232A', '#60E19B']}
                />
            </div>
        );
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div>
            <div className={AddUserClasses['main-user-address']}>
                <div className={AddUserClasses['main-userleft-address']}>
                    <h3 className={AddUserClasses['main-address-header']}>Address</h3>
                </div>
                <div className={AddUserClasses['main-userright-address']}>
                    <div className={CommonClasses['control-btns']}>
                        <Button
                            style={{ backgroundColor: 'white' }}
                            className={CommonClasses['button-for-add']}
                            onClick={handleClickOpen}
                        >
                            <BiPlus size={20} /> Add Address
                        </Button>
                    </div>
                </div>
            </div>
            <AddressList
                addresses={addresses}
                defaultAddress={defaultAddress}
                setDefaultAddress={setDefaultAddress}
                handleEditClick={handleEditClick}
                setAddresses={setAddresses}
                userId={userId}
            />
            <AddressForm
                open={open}
                editMode={editMode}
                formValues={formValues}
                setFormValues={setFormValues}
                handleClose={handleClose}
                handleSave={handleSave}
            />
        </div>
    );
};

export default EditUserAddress;
