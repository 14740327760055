import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Layout from 'Components/Layout';
import CommonClasses from 'Styles/Common.module.css';
import ProductClasses from '../Product/Products.module.css';
import BoardClasses from './Dashboard.module.css';
import { Services } from 'Services';
import CountUp from 'react-countup';
import { GiBoxUnpacking, GiMoneyStack } from "react-icons/gi";
import { SiBrandfolder, SiMoneygram } from "react-icons/si";
import { TbPackageExport } from "react-icons/tb";
import { FaBoxes } from "react-icons/fa";
import { HiArchiveBoxXMark } from "react-icons/hi2";
import { IoIosCheckbox, IoMdCheckbox } from 'react-icons/io';
import { BsFillTagsFill } from 'react-icons/bs';
import { RiFundsBoxFill } from 'react-icons/ri';

const Dashboard = () => {
    const [dashboardData, setDashboardData] = useState({
        todayOrdersCount: 0,
        totalOrders: 0,
        todayIncome: 0,
        totalIncome: 0,
        totalBrands: 0,
        totalDeals: 0,
        totalProducts: 0,
        deletedOrders: 0,
        unitsSold: 0,
    });
    
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');

    useEffect(() => {
        fetchDashboardData(); // Initial fetch without date filters
    }, []);

    const fetchDashboardData = async () => {

        let  queryparams = {}
        if(fromDate){
            queryparams= {fromDate}
            
        }
        if(toDate){
            queryparams = {...queryparams,toDate}

        }
        try {
            const token = { Authorization: `token ${localStorage.getItem('token')}` };
            // Construct the URL with optional query parameters for from and to dates
            const response = await Services.getDashBoard(token, queryparams);
            if (response.Status === 1) {
                setDashboardData({
                    todayOrdersCount: response.todayOrdersCount,
                    totalOrders: response.totalOrders,
                    todayIncome: response.todayIncome,
                    totalIncome: response.totalIncome,
                    totalBrands: response.totalBrands,
                    totalDeals: response.totalDeals,
                    totalProducts: response.totalProducts,
                    deletedOrders: response.deletedOrders,
                    unitsSold: response.unitsSold,
                });
            } else {
                console.error('Error fetching dashboard data:', response.Message);
            }
        } catch (error) {
            console.error('Error fetching dashboard data:', error);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        fetchDashboardData(fromDate, toDate); // Fetch data with selected dates
    };

    return (
        <Layout Active={"Dashboard"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Dashboard</title>
            </Helmet>
            <div className={CommonClasses['Container']}>
                <div className={CommonClasses['wrapper']}>
                    <h3 className={ProductClasses['main-header']}>Dashboard</h3>

                    {/* Date Filter Form */}
                    <form onSubmit={handleSubmit} className={BoardClasses['main-dashboard-datesearch']}>
                        <div className={BoardClasses['sub-dates-dash']}>
                            <label>From Date:</label>
                            <input 
                                type="date" 
                                value={fromDate} 
                                onChange={(e) => setFromDate(e.target.value)} 
                                required 
                            />
                        </div>
                        <div className={BoardClasses['sub-dates-dash']}>
                            <label>To Date:</label>
                            <input 
                                type="date" 
                                value={toDate} 
                                onChange={(e) => setToDate(e.target.value)} 
                                required 
                            />
                        </div>
                        <button type="submit">Submit</button>
                    </form>

                    <div className={BoardClasses['users-secondary-main']}>
                    <div className={BoardClasses['sec-card-two']} data-aos="zoom-in-down">
                            <div className={BoardClasses['inside-sec-one-card']}>
                                <h3><GiMoneyStack /></h3>
                            </div>
                            <div className={BoardClasses['inside-sec-one-two-card']}>
                                <p className={BoardClasses['text-sec-card']}>Today's Income</p>
                                <p className={BoardClasses['pendingpayment-text']}>
                                    <CountUp end={dashboardData.todayIncome} duration={2} prefix="₹" decimals={2} />
                                </p>
                            </div>
                        </div>    
                        <div className={BoardClasses['sec-card-three']} data-aos="zoom-in-down">
                            <div className={BoardClasses['inside-sec-one-card']}>
                                <h3><SiMoneygram /></h3>
                            </div>
                            <div className={BoardClasses['inside-sec-one-two-card']}>
                                <p className={BoardClasses['text-sec-card']}>Total Income</p>
                                <p className={BoardClasses['pendingtickets-text']}>
                                    <CountUp end={dashboardData.totalIncome} duration={2} prefix="₹" decimals={2} />
                                </p>
                            </div>
                        </div>
                        <div className={BoardClasses['sec-card-one']} data-aos="zoom-in-down">
                            <div className={BoardClasses['inside-sec-one-card']}>
                                <h3><TbPackageExport /></h3>
                            </div>
                            <div className={BoardClasses['inside-sec-one-two-card']}>
                                <p className={BoardClasses['text-sec-card']}>Today Orders</p>
                                <p className={BoardClasses['registration-text']}>
                                    <CountUp end={dashboardData.todayOrdersCount} duration={2} />
                                </p>
                            </div>
                        </div>                      
                        <div className={BoardClasses['sec-card-three']} data-aos="zoom-in-down">
                            <div className={BoardClasses['inside-sec-one-card']}>
                                <h3><GiBoxUnpacking /></h3>
                            </div>
                            <div className={BoardClasses['inside-sec-one-two-card']}>
                                <p className={BoardClasses['text-sec-card']}>Total Orders</p>
                                <p className={BoardClasses['pendingtickets-text']}>
                                    <CountUp end={dashboardData.totalOrders} duration={2} />
                                </p>
                            </div>
                        </div>
                        <div className={BoardClasses['sec-card-three']}>
                            <div className={BoardClasses['inside-sec-one-card']}>
                                <h3><IoIosCheckbox /></h3>
                            </div>
                            <div className={BoardClasses['inside-sec-one-two-card']}>
                                <p className={BoardClasses['text-sec-card']}>Paid Orders</p>
                                <p className={BoardClasses['pendingtickets-text']}>
                                    <CountUp end={dashboardData.paidOrder} duration={2} />
                                </p>
                            </div>
                        </div>
                        <div className={BoardClasses['sec-card-three']}>
                            <div className={BoardClasses['inside-sec-one-card']}>
                                <h3><FaBoxes /></h3>
                            </div>
                            <div className={BoardClasses['inside-sec-one-two-card']}>
                                <p className={BoardClasses['text-sec-card']}>Total Products</p>
                                <p className={BoardClasses['pendingtickets-text']}>
                                    <CountUp end={dashboardData.totalProducts} duration={2} />
                                </p>
                            </div>
                        </div>
                        <div className={BoardClasses['sec-card-three']}>
                            <div className={BoardClasses['inside-sec-one-card']}>
                                <h3><HiArchiveBoxXMark /></h3>
                            </div>
                            <div className={BoardClasses['inside-sec-one-two-card']}>
                                <p className={BoardClasses['text-sec-card']}>Deleted Orders</p>
                                <p className={BoardClasses['pendingtickets-text']}>
                                    <CountUp end={dashboardData.deletedOrders} duration={2} />
                                </p>
                            </div>
                        </div>
                        <div className={BoardClasses['sec-card-three']}>
                            <div className={BoardClasses['inside-sec-one-card']}>
                                <h3><BsFillTagsFill /></h3>
                            </div>
                            <div className={BoardClasses['inside-sec-one-two-card']}>
                                <p className={BoardClasses['text-sec-card']}>Total Deals</p>
                                <p className={BoardClasses['pendingtickets-text']}>
                                    <CountUp end={dashboardData.totalDeals} duration={2} />
                                </p>
                            </div>
                        </div>
                        <div className={BoardClasses['sec-card-three']}>
                            <div className={BoardClasses['inside-sec-one-card']}>
                                <h3><RiFundsBoxFill /></h3>
                            </div>
                            <div className={BoardClasses['inside-sec-one-two-card']}>
                                <p className={BoardClasses['text-sec-card']}>Units Sold</p>
                                <p className={BoardClasses['pendingtickets-text']}>
                                    <CountUp end={dashboardData.unitsSold} duration={2} />
                                </p>
                            </div>
                        </div>
                        <div className={BoardClasses['sec-card-three']}>
                            <div className={BoardClasses['inside-sec-one-card']}>
                                <h3><SiBrandfolder /></h3>
                            </div>
                            <div className={BoardClasses['inside-sec-one-two-card']}>
                                <p className={BoardClasses['text-sec-card']}>Total Brands</p>
                                <p className={BoardClasses['pendingtickets-text']}>
                                    <CountUp end={dashboardData.totalBrands} duration={2} />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Dashboard;
