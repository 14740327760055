import Layout from 'Components/Layout';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import CommonClasses from 'Styles/Common.module.css';
import ProductClasses from '../Product/Products.module.css';
import { useNavigate } from 'react-router-dom';
import { Services } from 'Services';
import FaqClasses from '../faq/Faq.module.css';
import { Success, Error } from 'Util/Toast'; // Assuming you have Error component for displaying error message
import ViewUserClasses from '../users/viewUsers/ViewUsers.module.css'
import AddUserClasses from '../users/addUser/AddUser.module.css'
import { Breadcrumbs, Button, Link, Stack, Typography } from '@mui/material';
import { MdOutlineArrowBackIos } from 'react-icons/md';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';


const AddFaq = () => {
    const navigate = useNavigate();
    const [question, setQuestion] = useState('');
    const [answer, setAnswer] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const handleAddFAQ = async () => {
        if (!question || !answer) {
            setError('Please fill in all fields.');
            return;
        }

        setLoading(true);
        try {
            let body = {
                question: question,
                answer: answer,

            }
            const token = { Authorization: `token ${localStorage.getItem('token')}` };
            const response = await Services.addFAQ(JSON.stringify(body), token);
            console.log('FAQ added successfully');
            navigate('/faq')
            Success(response.Message)
        } catch (error) {
            console.error('Error adding FAQ:', error);
            // Handle error, maybe show an error message
        } finally {
            setLoading(false);
        }
    };

    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" onClick={() => navigate('/faq')}>
            FAQs
        </Link>,
        <Typography key="2" color="text.primary">
            Add FAQs
        </Typography>,
    ];

    return (
        <Layout Active={'FAQs'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>FAQs</title>
            </Helmet>
            <div className={CommonClasses['Container']}>
                <div className={AddUserClasses['new-main-config-div']}>
                    <div className={ViewUserClasses['main-on-ctrl-btns']}>
                        <div className={CommonClasses['control-btns']}>
                            <Button className={CommonClasses["button-for-add"]} onClick={() => navigate(-1)}><MdOutlineArrowBackIos />Back</Button>
                        </div>
                        <Stack spacing={2}>
                            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                                {breadcrumbs}
                            </Breadcrumbs>
                        </Stack>
                    </div>
                    <div className={AddUserClasses['inside-new-main-btn']}>
                        <button style={{ width: '130px' }} className={FaqClasses['add-button']} onClick={handleAddFAQ}>
                            Save Changes
                        </button>
                    </div>
                </div>
                <div style={{marginTop: '40px'}} className={FaqClasses['create-main-head']} data-aos="zoom-in-down">
                    <div className={FaqClasses['main-h3-head']}>
                        <h3 className={FaqClasses['main-header']}>Create FAQs</h3>
                    </div>
                    {error && <p className={FaqClasses['error-message']}>{error}</p>}
                    <div className={FaqClasses['main-faq-bar']}>
                        <label htmlFor="question">Question:</label>
                        <input
                            type="text"
                            id="question"
                            className={FaqClasses['editable-input']}
                            value={question}
                            onChange={(e) => setQuestion(e.target.value)}
                            placeholder='Question *'
                        />
                    </div>
                    <div className={FaqClasses['main-faq-bar']}>
                        <label htmlFor="answer">Answer:</label>
                        <textarea
                            id="answer"
                            className={FaqClasses['editable-textarea']}
                            value={answer}
                            onChange={(e) => setAnswer(e.target.value)}
                            placeholder='Answer *'
                        />
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default AddFaq;
