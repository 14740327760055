import Layout from 'Components/Layout';
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import CommonClasses from 'Styles/Common.module.css';
import ProductClasses from '../Product/Products.module.css';
import { useNavigate, useParams } from 'react-router-dom';
import { Services } from 'Services';
import SkeletonLoad from "Components/TableLoad/load";
import { BiPlus, BiMinus } from 'react-icons/bi';
import { TextField, Button } from '@mui/material';
import Collapse from 'react-collapse';
import HelpClasses from '../help/Help.module.css';
import { MdKeyboardArrowDown } from "react-icons/md";
import { Success } from 'Util/Toast';
import { MdOutlineArrowBackIos } from 'react-icons/md';
import ViewUserClasses from '../users/viewUsers/ViewUsers.module.css'
import AddUserClasses from '../users/addUser/AddUser.module.css'
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import FaqClasses from '../faq/Faq.module.css';
import { Breadcrumbs, Grid, Link, Stack, Typography } from '@mui/material';


const UpdateHelp = () => {
    const { helpId } = useParams();
    const navigate = useNavigate();
    const [helpData, setHelpData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [updatedHelpData, setUpdatedHelpData] = useState(null); // State to store updated help data

    useEffect(() => {
        const fetchHelpData = async () => {
            try {
                const token = { Authorization: `token ${localStorage.getItem('token')}` };
                const response = await Services.getHelp(token);
                const selectedHelp = response.helps.find(help => help.helpId === parseInt(helpId));
                setHelpData(selectedHelp);
                setUpdatedHelpData(selectedHelp); // Initialize updated help data with fetched data
                setLoading(false);
            } catch (error) {
                console.error('Error fetching help data:', error);
                setLoading(false);
            }
        };

        fetchHelpData();

        return () => {
            // Cleanup code if any
        };
    }, [helpId]);

    const handleUpdate = async () => {
        try {
            let body = {
                heading: updatedHelpData.heading,
                solutions: updatedHelpData.solutions,
            }
            const token = { Authorization: `token ${localStorage.getItem('token')}` };
            // Call updateHelp API with updatedHelpData
            const response = await Services.updateHelp(helpData.helpId, JSON.stringify(body), token);
            navigate(-1); // Navigate back after successful update
            Success(response.Message)
        } catch (error) {
            console.error('Error updating help:', error);
        }
    };

    const handleChange = (event, index) => {
        const { name, value } = event.target;
        if (name === "heading") {
            setUpdatedHelpData(prevState => ({
                ...prevState,
                heading: value
            }));
        } else {
            setUpdatedHelpData(prevState => {
                const updatedSolutions = [...prevState.solutions];
                updatedSolutions[index][name] = value;
                return { ...prevState, solutions: updatedSolutions };
            });
        }
    };

    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" onClick={() => navigate('/help')}>
            Help
        </Link>,
        <Typography key="2" color="text.primary">
            Update Help
        </Typography>,
    ];

    return (
        <Layout Active={'Help'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Help</title>
            </Helmet>
            <div className={CommonClasses['Container']}>
                <div className={AddUserClasses['new-main-config-div']}>
                    <div className={ViewUserClasses['main-on-ctrl-btns']}>
                        <div className={CommonClasses['control-btns']}>
                            <Button className={CommonClasses["button-for-add"]} onClick={() => navigate(-1)}><MdOutlineArrowBackIos />Back</Button>
                        </div>
                        <Stack spacing={2}>
                            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                                {breadcrumbs}
                            </Breadcrumbs>
                        </Stack>
                    </div>
                    <div className={AddUserClasses['inside-new-main-btn']}>
                        <button style={{ width: '130px' }} className={FaqClasses['add-button']} onClick={handleUpdate}>
                            Save Changes
                        </button>
                    </div>
                </div>
                <div style={{marginTop: '40px'}} className={FaqClasses['create-main-head']} data-aos="zoom-in-down">
                    <div className={FaqClasses['main-h3-head']}>
                        <h3 className={FaqClasses['main-header']}>Update Help</h3>
                    </div>
                    {loading ? (
                        <SkeletonLoad TableHead={[]} />
                    ) : (
                        <div>
                            {helpData && (
                                <div>
                                    <TextField
                                        name="heading"
                                        label="Heading"
                                        value={updatedHelpData.heading}
                                        variant="outlined"
                                        fullWidth
                                        className={HelpClasses["help-heading-input"]}
                                        style={{ margin: '10px' }}
                                        onChange={(event) => handleChange(event)}
                                    />
                                    {updatedHelpData.solutions.map((solution, index) => (
                                        <div key={index}>
                                            <TextField
                                                name="Question"
                                                label="Question"
                                                value={solution.Question}
                                                variant="outlined"
                                                fullWidth
                                                className={HelpClasses["help-question-input"]}
                                                style={{ margin: '10px' }}
                                                onChange={(event) => handleChange(event, index)}
                                            />
                                            <TextField
                                                name="Answer"
                                                label="Answer"
                                                value={solution.Answer}
                                                variant="outlined"
                                                fullWidth
                                                multiline
                                                rows={4}
                                                className={HelpClasses["help-answer-input"]}
                                                style={{ margin: '10px' }}
                                                onChange={(event) => handleChange(event, index)}
                                            />
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    );
};

export default UpdateHelp;
