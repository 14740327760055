import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ViewUserClasses from './ViewUsers.module.css';
import { Services } from 'Services';
import Skeleton from '@mui/material/Skeleton';
import moment from 'moment';


const UserOverviewTable = () => {
    const { userId } = useParams();
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const token = { Authorization: `Bearer ${localStorage.getItem('token')}` };
                const response = await Services.getUserDetails(userId, token);
                setUser(response.user); // Adjust this line based on your API response structure
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchUserDetails();
    }, [userId]);

    if (loading) {
        // Show skeleton loading state while fetching data
        return (
            <table className={ViewUserClasses['user-table']}>
                <thead>
                    <tr>
                        <th colSpan="2" className={ViewUserClasses['table-header']}>About</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colSpan="2">
                            <div style={{padding: '5px'}} className={ViewUserClasses['skeleton-viewuseroverview']}>
                                <Skeleton variant="rectangular" width={300} height={30} />
                                <Skeleton variant="rectangular" width={640} height={30} />
                                <Skeleton variant="rectangular" width={300} height={30} />
                                <Skeleton variant="rectangular" width={640} height={30} />
                                <Skeleton variant="rectangular" width={300} height={30} />
                                <Skeleton variant="rectangular" width={640} height={30} />
                                <Skeleton variant="rectangular" width={300} height={30} />
                                <Skeleton variant="rectangular" width={640} height={30} />
                                <Skeleton variant="rectangular" width={300} height={30} />
                                <Skeleton variant="rectangular" width={640} height={30} />
                                <Skeleton variant="rectangular" width={300} height={30} />
                                <Skeleton variant="rectangular" width={640} height={30} />
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!user) {
        return <div>No user found.</div>;
    }

    const formatDate = (dateString) => {
        // console.log(dateString)
        return moment(dateString).format("DD-MM-YYYY");
    };

    return (
        <table className={ViewUserClasses['user-table']}>
            <thead>
                <tr>
                    <th colSpan="2" className={ViewUserClasses['table-header']}>About</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td className={ViewUserClasses['table-label']}>First name</td>
                    <td className={ViewUserClasses['table-value']}>{user.firstName}</td>
                </tr>
                <tr>
                    <td className={ViewUserClasses['table-label']}>Last name</td>
                    <td className={ViewUserClasses['table-value']}>{user.lastName}</td>
                </tr>
                <tr>
                    <td className={ViewUserClasses['table-label']}>Company</td>
                    <td className={ViewUserClasses['table-value']}>{user.company}</td>
                </tr>
                <tr>
                    <td className={ViewUserClasses['table-label']}>Email</td>
                    <td className={ViewUserClasses['table-value']}>{user.email}</td>
                </tr>
                <tr>
                    <td className={ViewUserClasses['table-label']}>Phone number</td>
                    <td className={ViewUserClasses['table-value']}>{user.mobileNumber}</td>
                </tr>
                <tr>
                    <td className={ViewUserClasses['table-label']}>Date joined</td>
                    <td className={ViewUserClasses['table-value']}>{formatDate(user.createdAt)}</td>
                </tr>
            </tbody>
        </table>
    );
};

export default UserOverviewTable;
