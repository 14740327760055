import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ViewUserClasses from '../../users/viewUsers/ViewUsers.module.css';
import OrderClasses from '../Orders.module.css';
import { Services } from 'Services';
import { Skeleton } from '@mui/material';

const OrderProducts = () => {
    const { orderId } = useParams();
    const [order, setOrder] = useState(null);
    const [user, setUser] = useState(null);
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchOrderDetails = async () => {
            try {
                const token = { Authorization: `Bearer ${localStorage.getItem('token')}` };
                const response = await Services.getOrderDetails(orderId, token);
                setOrder(response.order);
                setUser(response.user);
                setProducts(response.order.products);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchOrderDetails();
    }, [orderId]);

    if (loading) {
        // Show skeleton loading state while fetching data
        return (
            <table className={ViewUserClasses['user-table']}>
                <thead>
                    <tr>
                        <th style={{textAlign: 'center', width: '150px'}} className={ViewUserClasses['table-header']}>Product Id</th>
                        <th style={{textAlign: 'center', width: '150px'}} className={ViewUserClasses['table-header']}>Product</th>
                        <th style={{textAlign: 'center', width: '150px'}} className={ViewUserClasses['table-header']}>Qty</th>
                        <th style={{textAlign: 'center', width: '150px'}} className={ViewUserClasses['table-header']}>Price (Per Unit)</th>
                        <th style={{textAlign: 'center', width: '150px'}} className={ViewUserClasses['table-header']}>Total Price</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colSpan="5">
                            <div style={{padding: '5px'}} className={ViewUserClasses['skeleton-viewuseroverview']}>
                                <Skeleton variant="rectangular" width={170} height={30} />
                                <Skeleton variant="rectangular" width={170} height={30} />
                                <Skeleton variant="rectangular" width={170} height={30} />
                                <Skeleton variant="rectangular" width={170} height={30} />
                                <Skeleton variant="rectangular" width={170} height={30} />
                                <Skeleton variant="rectangular" width={170} height={30} />
                                <Skeleton variant="rectangular" width={170} height={30} />
                                <Skeleton variant="rectangular" width={170} height={30} />
                                <Skeleton variant="rectangular" width={170} height={30} />
                                <Skeleton variant="rectangular" width={170} height={30} />
                                <Skeleton variant="rectangular" width={170} height={30} />
                                <Skeleton variant="rectangular" width={170} height={30} />
                                <Skeleton variant="rectangular" width={170} height={30} />
                                <Skeleton variant="rectangular" width={170} height={30} />
                                <Skeleton variant="rectangular" width={170} height={30} />
                                <Skeleton variant="rectangular" width={170} height={30} />
                                <Skeleton variant="rectangular" width={170} height={30} />
                                <Skeleton variant="rectangular" width={170} height={30} />
                                <Skeleton variant="rectangular" width={170} height={30} />
                                <Skeleton variant="rectangular" width={170} height={30} />
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!order || products.length === 0) {
        return <div>No products found.</div>;
    }

    return (
        <div>
            <div className={OrderClasses['ordertable-head']}>
                <h4>Products</h4>
            </div>
            <table className={OrderClasses['orderuser-table']}>
                <thead style={{borderBottom: '1px solid #ddd'}}>
                    <tr>
                        <th style={{ textAlign: 'left', paddingLeft: '57px' }}>Product ID</th>
                        <th>Product</th>
                        <th>Qty</th>
                        <th>Price (per unit)</th>
                        <th>Total price</th>
                    </tr>
                </thead>
                <tbody>
                    {products.map((product, index) => (
                        <tr key={index}>
                            <td style={{ textAlign: 'left', paddingLeft: '57px' }}>{product.productId}</td>
                            <td className={ViewUserClasses['table-value']}>
                                <div className={ViewUserClasses['product-cell']}>
                                    {product.productImage && product.productImage.length > 0 && (
                                        <img 
                                            src={product.productImage[0]} 
                                            alt={`${product.productName} image`} 
                                            className={ViewUserClasses['product-image']} 
                                        />
                                    )}
                                    <span>{product.productName}</span>
                                </div>
                            </td>
                            <td className={ViewUserClasses['table-value']}>{product.qty}</td>
                            <td className={ViewUserClasses['table-value']}>
                                ₹ {product.productPrice} 
                                {product.productDiscountPrice < product.productPrice && (
                                    <span style={{ textDecoration: 'line-through', marginLeft: '5px' }}>
                                        ₹ {product.productDiscountPrice}
                                    </span>
                                )}
                            </td>
                            <td className={ViewUserClasses['table-value']}>₹ {product.qty * product.productDiscountPrice}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default OrderProducts;
