
import uuid from "react-uuid";

import { IoIosFolder, IoMdWallet } from 'react-icons/io'
import { FaUsers } from "react-icons/fa";
import { FaBoxes } from "react-icons/fa";
import { FaBoxOpen } from "react-icons/fa";
import { TbAlignBoxRightTopFilled } from "react-icons/tb";
import { RiCoupon3Fill } from "react-icons/ri";
import { FcFaq } from "react-icons/fc";
import { FcDataConfiguration } from "react-icons/fc";
import { MdLiveHelp } from "react-icons/md";
import { RiUserSettingsFill } from "react-icons/ri";
import { MdDashboard } from "react-icons/md";
import { FaQuestion } from "react-icons/fa";
import { IoMdSettings } from "react-icons/io";

export const permissions = {
   Dashboard: { read: ["view-Dashboard"] },
   Users: { read: ["view-Users"] },
   Orders: { read: ['view-Orders'] },
   Products: { read: ['view-Products'] },
   ProductType: { read: ['view-ProductType'] },
   ProductBrand: { read: ['view-ProductBrand'] },
   ProductDeal: { read: ['view-ProductDeal'] },
   ProductCategory: { read: ['view-ProductCategory'] },
   Banners: { read: ['view-Banners'] },
   Coupons: { read: ['view-Coupons'] },
   FAQs: { read: ['view-FAQs'] },
   Config: { read: ['view-Config'] },
   Help: { read: ['view-Help'] },
   AccountSettings: { read: ["view-AccountSettings"] },
   AdminSettings: { read: ["view-AdminSettings"] },
   TeamSettings: { read: ["view-TeamSettings"] },
}



export const menuItems = [

   {
      id: uuid(),
      multiple: false,
      name: "Dashboard",
      menuLink: "/dashboard",
      active: "Dashboard",
      icon: MdDashboard,
      Permissions: permissions.Dashboard.read,
     },

 {
    id: uuid(),
    multiple: false,
    name: "Users",
    menuLink: "/users",
    active: "Users",
    icon: FaUsers,
    Permissions: permissions.Users.read,
   },

   {
    id: uuid(),
    multiple: false,
    name: "Orders",
    menuLink: "/orders",
    active: "Orders",
    icon: FaBoxOpen,
   Permissions: permissions.Orders.read,
   },

   {
    id: uuid(),
    multiple: false,
    name: "Products",
    menuLink: "/products",
    active: "Products",
    icon: FaBoxes,
   },

   {
    id: uuid(),
    multiple: true,
    name: "Product Configuration",
    menuLink: "/productconfiguration",
    active: "Product Configuration",
    icon: TbAlignBoxRightTopFilled,
    items: [
       {
          id:'1',
          multiple: false,
          name: "Product Type",
          menuLink: "/producttype",
          active: "ProductType",
          Permissions: permissions.ProductType.read,
       },
       {
          id:'2',
          multiple: false,
          name: "Product Brand",
          menuLink: "/productbrand",
          active: "Product Brand",
          Permissions: permissions.ProductBrand.read,
       },
       {
        id:'3',
        multiple: false,
        name: "Product Deal",
        menuLink: "/productdeal",
        active: "Product Deal",
        Permissions: permissions.ProductDeal.read,
     },
     {
        id:'4',
        multiple: false,
        name: "Product Category",
        menuLink: "/productcategory",
        active: "Product Category",
        Permissions: permissions.ProductCategory.read,
     },
   //   {
   //    id:'5',
   //    multiple: false,
   //    name: "Deals Ending Soon",
   //    menuLink: "/dealsendingsoon",
   //    active: "Deals Ending Soon",
   //    // Permissions: permissions.Wallet.read,
   // },
    ]
 },

 {
   id: uuid(),
   multiple: false,
   name: "Banners",
   menuLink: "/banners",
   active: "Banners",
   icon: FaBoxes,

   Permissions: permissions.Banners.read,
},

 {
    id: uuid(),
    multiple: false,
    name: "Coupons",
    menuLink: "/coupons",
    active: "Coupons",
    icon: RiCoupon3Fill,
    Permissions: permissions.Coupons.read,
   },
  
   {
    id: uuid(),
    multiple: false,
    name: "FAQs",
    menuLink: "/faqs",
    active: "FAQs",
    icon: FaQuestion,
    Permissions: permissions.FAQs.read,
   },

   {
    id: uuid(),
    multiple: false,
    name: "Config",
    menuLink: "/config",
    active: "Config",
    icon: IoMdSettings,
    Permissions: permissions.Config.read,
   },

   {
    id: uuid(),
    multiple: false,
    name: "Help",
    menuLink: "/help",
    active: "Help",
    icon: MdLiveHelp,
    Permissions: permissions.Help.read,
   },
  
   {
    id: uuid(),
    multiple: true,
    name: "Admin Settings",
    menuLink: "/adminsettings",
    active: "Admin Settings",
    icon: RiUserSettingsFill,
    items: [
       {
          id:'1',
          multiple: false,
          name: "Account Settings",
          menuLink: "/accountsettings",
          active: "Account Settings",
          Permissions: permissions.AccountSettings.read,
       },
       {
         id:'2',
         name: "TeamSettings",
         menuLink: "/permissions",
         active: "TeamSettings",
         icon: RiUserSettingsFill,
         Permissions: permissions.TeamSettings.read,
      }
    ]
 },
  


];

