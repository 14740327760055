import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ViewUserClasses from '../../users/viewUsers/ViewUsers.module.css';
import { Services } from 'Services';
import { Skeleton, TextField } from '@mui/material';
import AddUserClasses from '../../users/addUser/AddUser.module.css'

const ProductDescription = () => {
    const { productId } = useParams();
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const token = { Authorization: `Bearer ${localStorage.getItem('token')}` };
                const response = await Services.getProductDetails(productId, token);
                setProduct(response.product); // Adjust this line based on your API response structure
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchUserDetails();
    }, [productId]);

    if (loading) {
        // Show skeleton loading state while fetching data
        return (
            <table className={ViewUserClasses['user-table']}>
                <thead>
                    <tr>
                        <th colSpan="2" className={ViewUserClasses['table-header']}>Description</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colSpan="2">
                            <div style={{padding: '5px'}} className={ViewUserClasses['skeleton-viewuseroverview']}>
                                <Skeleton variant="rectangular" width={300} height={30} />
                                <Skeleton variant="rectangular" width={640} height={30} />
                                <Skeleton variant="rectangular" width={300} height={30} />
                                <Skeleton variant="rectangular" width={640} height={30} />
                                <Skeleton variant="rectangular" width={300} height={30} />
                                <Skeleton variant="rectangular" width={640} height={30} />
                                <Skeleton variant="rectangular" width={300} height={30} />
                                <Skeleton variant="rectangular" width={640} height={30} />
                                <Skeleton variant="rectangular" width={300} height={30} />
                                <Skeleton variant="rectangular" width={640} height={30} />
                                <Skeleton variant="rectangular" width={300} height={30} />
                                <Skeleton variant="rectangular" width={640} height={30} />
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!product) {
        return <div>No user found.</div>;
    }

    return (
        <table className={ViewUserClasses['user-table']}>
            <thead>
                <tr>
                    <th colSpan="2" className={ViewUserClasses['table-header']}>Description</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td className={AddUserClasses['table-label']}>Description</td>
                    <td className={AddUserClasses['table-value']}>
                        <TextField
                            required
                            id="productDescription"
                            name="productDescription"
                            value={product.productDescription || ''}
                            variant="outlined"
                            margin="dense"
                            multiline
                            fullWidth
                            rows={4}
                            className={AddUserClasses['table-textfield-all']}
                        />
                    </td>
                </tr>
                <tr>
                    <td className={AddUserClasses['table-label']}>Product Use</td>
                    <td className={AddUserClasses['table-value']}>
                        <TextField
                            required
                            id="productUse"
                            name="productUse"
                            value={product.productUse || ''}
                            variant="outlined"
                            margin="dense"
                            multiline
                            fullWidth
                            rows={4}
                            className={AddUserClasses['table-textfield-all']}
                        />
                    </td>
                </tr>
                <tr>
                    <td className={AddUserClasses['table-label']}>Product Benefits</td>
                    <td className={AddUserClasses['table-value']}>
                        <TextField
                            required
                            id="productBenefits"
                            name="productBenefits"
                            value={product.productBenefits || ''}
                            variant="outlined"
                            margin="dense"
                            multiline
                            fullWidth
                            rows={4}
                            className={AddUserClasses['table-textfield-all']}
                        />
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

export default ProductDescription;
