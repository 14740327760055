import React, { useState, useEffect } from 'react';
import { TextField, Button, Checkbox, FormControlLabel } from '@mui/material';
import AddUserClasses from '../../users/addUser/AddUser.module.css';
import { BiPlus } from 'react-icons/bi';
import { IoIosRemoveCircle } from 'react-icons/io';
import CommonClasses from '../../../Styles/Common.module.css';

const AddProductPricing = ({ userData, setUserData }) => {
    const [priceTiers, setPriceTiers] = useState(userData.priceTiers || []);
    const [lastMinutePick, setLastMinutePick] = useState(userData.lastMinutePick);
    const [popularProduct, setPopularProduct] = useState(userData.popularProduct);
    const [moq, setMoq] = useState(userData.moq);

    useEffect(() => {
        setUserData((prevData) => ({
            ...prevData,
            priceTiers,
            lastMinutePick,
            popularProduct,
            moq
        }));
    }, [priceTiers, lastMinutePick, popularProduct, moq, setUserData]);

    const addPriceTierField = () => {
        setPriceTiers([...priceTiers, { aboveQty: '', price: '' }]);
    };

    const handlePriceTierChange = (index, field, value) => {
        const newPriceTiers = priceTiers.map((tier, i) => (
            i === index ? { ...tier, [field]: parseFloat(value) } : tier
        ));
        setPriceTiers(newPriceTiers);
    };

    const removePriceTierField = (index) => {
        setPriceTiers(priceTiers.filter((_, i) => i !== index));
    };

    const handleLastMinutePickChange = (event) => {
        setLastMinutePick(event.target.checked ? 1 : 0);
    };

    const handlePopularProductChange = (event) => {
        setPopularProduct(event.target.checked ? 1 : 0);
    };

    const handleMoqChange = (event) => {
        setMoq(event.target.checked ? 1 : 0);
    };

    return (
        <div>
            <table className={AddUserClasses['user-table']}>
                <thead>
                    <tr>
                        <th colSpan={3} style={{ width: '100%', textIndent: '13px' }} className={AddUserClasses['table-header']}>Price Tiers</th>
                    </tr>
                </thead>
                <tbody>
                    {priceTiers.map((tier, index) => (
                        <tr key={index}>
                            <td className={AddUserClasses['table-label']}>
                                <TextField
                                    style={{ width: '400px' }}
                                    label={`Price for Quantity Above`}
                                    variant="outlined"
                                    margin="dense"
                                    value={tier.aboveQty}
                                    onChange={(e) => handlePriceTierChange(index, 'aboveQty', e.target.value)}
                                    className={AddUserClasses['table-textfield-all']}
                                    type='number'
                                    size="small"
                                />
                            </td>
                            <td className={AddUserClasses['table-value']}>
                                <TextField
                                    style={{ width: '400px', marginLeft: '40px' }}
                                    label={`Price`}
                                    variant="outlined"
                                    margin="dense"
                                    value={tier.price}
                                    onChange={(e) => handlePriceTierChange(index, 'price', e.target.value)}
                                    className={AddUserClasses['table-textfield-all']}
                                    type='number'
                                    size="small"
                                />
                            </td>
                            <td className={AddUserClasses['table-value']}>
                                <Button
                                    className={CommonClasses['button-for-add']}
                                    onClick={() => removePriceTierField(index)}
                                >
                                    <IoIosRemoveCircle />
                                </Button>
                            </td>
                        </tr>
                    ))}
                    <tr>
                        <td colSpan={3}>
                            <div className={CommonClasses['control-btns']}>
                                <Button
                                    style={{marginLeft: '20px', backgroundColor: '#23232A', color: 'white'}}
                                    type="button"
                                    variant="outlined"
                                    onClick={addPriceTierField}
                                    className={CommonClasses['button-for-add']}
                                    startIcon={<BiPlus />}
                                >
                                    Add Price & Quantity
                                </Button>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={3}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={lastMinutePick === 1}
                                        onChange={handleLastMinutePickChange}
                                        color="primary"
                                    />
                                }
                                label="Last Minute Pick"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={3}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={popularProduct === 1}
                                        onChange={handlePopularProductChange}
                                        color="primary"
                                    />
                                }
                                label="Popular Product"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={3}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={moq === 1}
                                        onChange={handleMoqChange}
                                        color="primary"
                                    />
                                }
                                label="MOQ"
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default AddProductPricing;
