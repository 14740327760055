import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import Layout from 'Components/Layout';
import CommonClasses from 'Styles/Common.module.css';
import ProductClasses from '../Product/Products.module.css';
import { Accordion, AccordionSummary, AccordionDetails, Button, Modal, Box, TextField } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { RiLockPasswordLine } from 'react-icons/ri';
import AdminClasses from './AccountSettings.module.css'
import { Services } from 'Services';
import { Success } from 'Util/Toast';

const AccountSettings = () => {
    // State to manage the modal's open/close status
    const [isModalOpen, setModalOpen] = useState(false);

    // State to manage the form fields
    const [formData, setFormData] = useState({
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: ''
    });

    // Function to handle opening the modal
    const handleOpenModal = () => {
        setModalOpen(true);
    };

    // Function to handle closing the modal
    const handleCloseModal = () => {
        setModalOpen(false);
    };

    // Function to handle form field changes
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    // Function to handle form submission
    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent form from submitting the default way
    
        // Destructure the form data
        const { currentPassword, newPassword, confirmNewPassword } = formData;
    
        // Validate that newPassword and confirmNewPassword match
        if (newPassword !== confirmNewPassword) {
            console.error("New password and confirm new password do not match.");
            return;
        }

        let body = {
            currentPassword: currentPassword,
            newPassword: newPassword,
        }
    
        try {
            // Get the authentication token from localStorage
            const token = { Authorization: `token ${localStorage.getItem('token')}` };
    
            // Call the resetPassword API
            const response = await Services.resetPassword(body, token);
    
            // Check the response status and handle the result
            if (response.Status === 1) {
                console.log('Password changed successfully:', response.Message);
                // Show a success message or take appropriate action
                // Close the modal
                handleCloseModal();
                Success(response.Message)
            } else {
                console.error('Error changing password:', response.Message);
            }
        } catch (error) {
            console.error('Error calling resetPassword API:', error);
        }
    };
    

    return (
        <Layout Active={"Account Settings"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Account Settings</title>
            </Helmet>
            <div className={CommonClasses['Container']}>
                <div className={CommonClasses['wrapper']}>
                    <h3 className={ProductClasses['main-header']}>Account Settings</h3>
                    
                    {/* Accordion */}
                    <Accordion>
                        {/* Accordion Summary */}
                        <AccordionSummary expandIcon={<ExpandMore />} className={AdminClasses["panel1a-header"]}>
                            <h4>Change Password</h4>
                        </AccordionSummary>

                        {/* Accordion Details */}
                        <AccordionDetails>
                            <Button
                                variant="contained"
                                color="secondary"
                                size="small" // Set the button size to small
                                onClick={handleOpenModal}
                                startIcon={<RiLockPasswordLine />} // Add the icon for change password
                            >
                                Change Password..?
                            </Button>

                            {/* Modal */}
                            <Modal open={isModalOpen} onClose={handleCloseModal} data-aos="zoom-in-down">
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        width: 400,
                                        bgcolor: 'background.paper',
                                        border: '2px solid #000',
                                        boxShadow: 24,
                                        p: 4,
                                        borderRadius: 2
                                    }}
                                >
                                    <h2>Change Password</h2>
                                    <form onSubmit={handleSubmit}>
                                        {/* Current Password */}
                                        <TextField
                                            label="Current Password"
                                            type="password"
                                            name="currentPassword"
                                            value={formData.currentPassword}
                                            onChange={handleChange}
                                            fullWidth
                                            margin="normal"
                                            required
                                        />
                                        {/* New Password */}
                                        <TextField
                                            label="New Password"
                                            type="password"
                                            name="newPassword"
                                            value={formData.newPassword}
                                            onChange={handleChange}
                                            fullWidth
                                            margin="normal"
                                            required
                                        />
                                        {/* Confirm New Password */}
                                        <TextField
                                            label="Confirm New Password"
                                            type="password"
                                            name="confirmNewPassword"
                                            value={formData.confirmNewPassword}
                                            onChange={handleChange}
                                            fullWidth
                                            margin="normal"
                                            required
                                        />

                                        {/* Submit button */}
                                        <Button variant="contained" color="primary" type="submit">
                                            Submit
                                        </Button>
                                    </form>
                                </Box>
                            </Modal>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </div>
        </Layout>
    );
};

export default AccountSettings;
