import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Breadcrumbs, Link, Typography, Button, Stack, Tabs, Tab, Box } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { MdOutlineArrowBackIos } from "react-icons/md";
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import Layout from 'Components/Layout';
import CommonClasses from 'Styles/Common.module.css';
import ViewUserClasses from '../../users/viewUsers/ViewUsers.module.css';
// import UserOverviewTable from './UserOverviewTable';
// import UserAddressTable from './UserAddressTable';
// import UserDocuments from './UserDocuments';
// import UserOrders from './UserOrders';
import AddUserClasses from '../../users/addUser/AddUser.module.css';
import OrderGeneral from './OrderGeneral';
import OrderCustomerShipping from './OrderCustomerShipping';
import OrderProducts from './OrderProducts';
import { Services } from 'Services';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
};

const ViewOrder = () => {
    const navigate = useNavigate();
    const { orderId } = useParams();
    const { state } = useLocation();
    const [tabIndex, setTabIndex] = useState(0);
    const [order, setOrder] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" onClick={() => navigate('/')}>
            Home
        </Link>,
        <Link underline="hover" key="2" color="inherit" onClick={() => navigate('/orders')}>
            Orders
        </Link>,
        <Typography key="3" color="text.primary">
            View order
        </Typography>,
    ];

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const handleGenerateInvoice = () => {
        if (order && order.invoice) {
            window.open(order.invoice, '_blank');
        } else {
            console.error("Invoice URL not available");
        }
    };

    const getStatusBackgroundColor = (status) => {
        switch (status) {
            case 1:
                return '#f3f3f3'; // New Order
            case 2:
                return '#5bc0de'; // Ready for pickup
            case 3:
                return '#feffb9'; // Shipped
            case 4:
                return '#79f4a3'; // Delivered
            case 5:
                return '#ffa7a7'; // Returned
            default:
                return '#E0E0E0'; // NA
        }
    };

    useEffect(() => {
        const fetchOrderDetails = async () => {
            try {
                const token = { Authorization: `Bearer ${localStorage.getItem('token')}` };
                const response = await Services.getOrderDetails(orderId, token);
                setOrder(response.order); // Adjust this line based on your API response structure
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchOrderDetails();
    }, [orderId]);

    return (
        <Layout Active={'Orders'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Orders</title>
            </Helmet>
            <div className={AddUserClasses['main-of-tables']}>
                <div className={AddUserClasses['new-main-div']}>
                    <div className={ViewUserClasses['main-on-ctrl-btns']}>
                        <div className={CommonClasses['control-btns']}>
                            <Button className={CommonClasses["button-for-add"]} onClick={() => navigate(-1)}><MdOutlineArrowBackIos />Back</Button>
                        </div>
                        <Stack spacing={2}>
                            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                                {breadcrumbs}
                            </Breadcrumbs>
                        </Stack>
                    </div>
                </div>
                {order ? (
                    <div>
                        <div className={ViewUserClasses['main-name']}>
                            <div className={ViewUserClasses['inside-leftname']}>
                                <h1 className={ViewUserClasses['user-fullname']}>{order.orderId}</h1>
                                <p 
                                    className={ViewUserClasses['user-para-stat']} 
                                    style={{ backgroundColor: getStatusBackgroundColor(order.orderDeliveryStatus) }}
                                >
                                    {order.orderDeliveryStatus === 1 ? 'New Order' : order.orderDeliveryStatus === 2 ? 'Ready for pickup' : order.orderDeliveryStatus === 3 ? 'Shipped' : order.orderDeliveryStatus === 4 ? 'Delivered' : order.orderDeliveryStatus === 5 ? 'Returned' : 'NA'}
                                </p>
                                <p style={{ backgroundColor: order.paymentStatus === 1 ? '#FFE600' : '#79f4a3', color: 'white' }} className={ViewUserClasses['user-para-stat']}>{order.paymentStatus === 1 ? 'Un Paid' : 'Paid'}</p>
                            </div>
                            <div className={ViewUserClasses['inside-rightname']}>
                                <button className={ViewUserClasses['invoice-btn']} onClick={handleGenerateInvoice}>Generate Invoice</button>
                            </div>
                        </div>
                        <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                            <Tabs className={ViewUserClasses['tabs-main-head']} value={tabIndex} onChange={handleTabChange} aria-label="order details tabs">
                                <Tab className={ViewUserClasses['tabs-main-sub-head']} label="General" />
                                <Tab label="Customer & Shipping" />
                                <Tab label="Products" />
                            </Tabs>
                            <TabPanel value={tabIndex} index={0}>
                                <OrderGeneral order={order} />
                            </TabPanel>
                            <TabPanel value={tabIndex} index={1}>
                                <OrderCustomerShipping order={order} />
                            </TabPanel>
                            <TabPanel value={tabIndex} index={2}>
                                <OrderProducts order={order} />
                            </TabPanel>
                        </Box>
                    </div>
                ) : (
                    <div>
                        <p>No Order Found</p>
                    </div>
                )}
            </div>
        </Layout>
    );
};

export default ViewOrder;
