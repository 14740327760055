import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, Typography, IconButton, TextField, Grid, MenuItem, FormControl, InputLabel, Select, FormHelperText } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Country, State } from 'country-state-city';

const AddShippingAddressDialog = ({ open, handleClose, handleSave, shippingAddress: initialAddress, errors: initialErrors }) => {
    const [shippingAddress, setShippingAddress] = useState({
        firstName: initialAddress.firstName || '',
        lastName: initialAddress.lastName || '',
        company: initialAddress.company || '',
        mobileNumber: initialAddress.mobileNumber || '',
        deliveryAddress: initialAddress.deliveryAddress || '',
        city: initialAddress.city || '',
        state: initialAddress.state || '',
        area: initialAddress.area || '',
        pinCode: initialAddress.pinCode || '',
        flatNo: initialAddress.flatNo || '',
        addressType: initialAddress.addressType || 'Home', // Initialize with a default value
        country: initialAddress.country || '',
        countryCode: initialAddress.countryCode || '',
        stateCode: initialAddress.stateCode || '',
    });

    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [errors, setErrors] = useState(initialErrors || {});

    useEffect(() => {
        setCountries(Country.getAllCountries());
        if (shippingAddress.countryCode) {
            setStates(State.getStatesOfCountry(shippingAddress.countryCode));
        }
    }, []);

    useEffect(() => {
        validate();
    }, [shippingAddress]);

    const handleChange = (event) => {
        const { name, value } = event.target;

        // Special handling for addressType directly setting the selected value
        setShippingAddress((prevAddress) => ({
            ...prevAddress,
            [name]: value,
        }));
    };

    const handleSaveClick = () => {
        validate();
        if (Object.keys(errors).length === 0) {
            handleSave(shippingAddress);
        }
    };

    const handleCountryChange = (event) => {
        const selectedCountryCode = event.target.value;
        const selectedCountry = countries.find(country => country.isoCode === selectedCountryCode);

        setShippingAddress(prevAddress => ({
            ...prevAddress,
            country: selectedCountry.name,
            countryCode: selectedCountry.isoCode,
            state: '',
            stateCode: ''
        }));

        setStates(State.getStatesOfCountry(selectedCountryCode));
    };

    const handleStateChange = (event) => {
        const selectedStateCode = event.target.value;
        const selectedState = states.find(state => state.isoCode === selectedStateCode);

        setShippingAddress(prevAddress => ({
            ...prevAddress,
            state: selectedState.name,
            stateCode: selectedState.isoCode
        }));
    };

    const validate = () => {
        let errors = {};

        if (!/^\d{10}$/.test(shippingAddress.mobileNumber)) {
            errors.mobileNumber = 'Mobile number should be 10 digits *';
        }

        if (!/^\d{6}$/.test(shippingAddress.pinCode)) {
            errors.pinCode = 'Pin code should be 6 digits *';
        }

        if (!(shippingAddress.deliveryAddress)) {
            errors.deliveryAddress = 'Delivery Address is Required *';
        }

        if (!(shippingAddress.company)) {
            errors.company = 'Company is Required *';
        }

        if (!(shippingAddress.firstName)) {
            errors.firstName = 'First Name is Required *';
        }

        if (!(shippingAddress.lastName)) {
            errors.lastName = 'Last Name is Required *';
        }

        if (!(shippingAddress.city)) {
            errors.city = 'City is Required *';
        }

        if (!(shippingAddress.country)) {
            errors.country = 'Country is Required *';
        }

        if (!(shippingAddress.state)) {
            errors.state = 'State is Required *';
        }

        if (!(shippingAddress.area)) {
            errors.area = 'Area is Required *';
        }

        if (!(shippingAddress.flatNo)) {
            errors.flatNo = 'Flat No is Required *';
        }

        if (!(shippingAddress.addressType)) {
            errors.addressType = 'Address Type is Required *';
        }

        setErrors(errors);
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="h6">Add Shipping Address</Typography>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                <form>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="First Name *"
                                variant="outlined"
                                margin="normal"
                                name="firstName"
                                value={shippingAddress.firstName}
                                onChange={handleChange}
                                error={!!errors.firstName}
                                helperText={errors.firstName}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Last Name *"
                                variant="outlined"
                                margin="normal"
                                name="lastName"
                                value={shippingAddress.lastName}
                                onChange={handleChange}
                                error={!!errors.lastName}
                                helperText={errors.lastName}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Company *"
                                variant="outlined"
                                margin="normal"
                                name="company"
                                value={shippingAddress.company}
                                onChange={handleChange}
                                error={!!errors.company}
                                helperText={errors.company}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Mobile Number *"
                                variant="outlined"
                                margin="normal"
                                name="mobileNumber"
                                value={shippingAddress.mobileNumber}
                                onChange={handleChange}
                                error={!!errors.mobileNumber}
                                helperText={errors.mobileNumber}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Delivery Address (Apartment, flat/house no) *"
                                variant="outlined"
                                margin="normal"
                                name="deliveryAddress"
                                value={shippingAddress.deliveryAddress}
                                onChange={handleChange}
                                error={!!errors.deliveryAddress}
                                helperText={errors.deliveryAddress}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="City *"
                                variant="outlined"
                                margin="normal"
                                name="city"
                                value={shippingAddress.city}
                                onChange={handleChange}
                                error={!!errors.city}
                                helperText={errors.city}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                select
                                label="Country *"
                                variant="outlined"
                                margin="normal"
                                name="countryCode"
                                value={shippingAddress.countryCode}
                                onChange={handleCountryChange}
                                error={!!errors.country}
                                helperText={errors.country}
                            >
                                {countries.map((country) => (
                                    <MenuItem key={country.isoCode} value={country.isoCode}>
                                        {country.name} ({country.isoCode})
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                select
                                label="State *"
                                variant="outlined"
                                margin="normal"
                                name="stateCode"
                                value={shippingAddress.stateCode}
                                onChange={handleStateChange}
                                error={!!errors.state}
                                helperText={errors.state}
                            >
                                {states.map((state) => (
                                    <MenuItem key={state.isoCode} value={state.isoCode}>
                                        {state.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Area *"
                                variant="outlined"
                                margin="normal"
                                name="area"
                                value={shippingAddress.area}
                                onChange={handleChange}
                                error={!!errors.area}
                                helperText={errors.area}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Pin Code *"
                                variant="outlined"
                                margin="normal"
                                name="pinCode"
                                value={shippingAddress.pinCode}
                                onChange={handleChange}
                                error={!!errors.pinCode}
                                helperText={errors.pinCode}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth margin="normal" error={Boolean(errors.addressType)}>
                                <InputLabel>Address Type</InputLabel>
                                <Select
                                    value={shippingAddress.addressType}
                                    onChange={handleChange}
                                    name="addressType"
                                    // type='number'
                                >
                                    <MenuItem value="1">Home</MenuItem>
                                    <MenuItem value="2">Office</MenuItem>
                                    <MenuItem value="3">Others</MenuItem>
                                </Select>
                                {errors.addressType && (
                                    <FormHelperText>{errors.addressType}</FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Flat No *"
                                variant="outlined"
                                margin="normal"
                                name="flatNo"
                                value={shippingAddress.flatNo}
                                onChange={handleChange}
                                error={!!errors.flatNo}
                                helperText={errors.flatNo}
                            />
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
            <DialogActions>
                <Button style={{ border: '2px solid #E8E8E8', color: 'black', width: '120px' }} onClick={handleClose} color="primary">Cancel</Button>
                <Button style={{ backgroundColor: '#000000', color: 'white', width: '120px' }} onClick={handleSaveClick} color="primary">Save</Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddShippingAddressDialog;
