import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { BiPlus } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import CommonClasses from "Styles/Common.module.css";
import ProductClasses from '../Product/Products.module.css';
import SkeletonLoad from "Components/TableLoad/load";
import { Services } from 'Services';
import { Modal, TextField, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Menu, MenuItem } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Success, Error_Dark } from 'Util/Toast';
import Layout from 'Components/Layout';

const ProductDeal = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [homeDeals, setHomeDeals] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [newDeal, setNewDeal] = useState('');
    const [selectedDeal, setSelectedDeal] = useState(null);
    const [deleteConfirmation, setDeleteConfirmation] = useState(false);
    const [deleteDealId, setDeleteDealId] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [isDeleteVisible, setIsDeleteVisible] = useState(true); // New state for delete visibility


    useEffect(() => {
        fetchHomeDeals();
    }, []);

    const fetchHomeDeals = async () => {
        try {
            const token = { Authorization: `token ${localStorage.getItem('token')}` };
            const response = await Services.getDeals(token);
            if (response.Status === 1) {
                setHomeDeals(response.homeDeals);
            } else {
                console.error('Error fetching homeDeals:', response.Message);
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching homeDeals:', error);
            setLoading(false);
        }
    };

    const handleOpenModal = (deal) => {
        setSelectedDeal(deal || null);
        setNewDeal(deal ? deal.dealName : '');
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setSelectedDeal(null);
        setNewDeal('');
        setOpenModal(false);
    };

    const handleAddDeal = async () => {
        let body = { dealName: newDeal };
        try {
            const token = { Authorization: `token ${localStorage.getItem('token')}` };
            const response = await Services.addDeals(JSON.stringify(body), token);
            if (response.Status === 1) {
                fetchHomeDeals();
                handleCloseModal();
                Success(response.Message);
            } else {
                console.error('Error adding deal:', response.Message);
            }
        } catch (error) {
            console.error('Error adding deal:', error);
        }
    };

    const handleEditDeal = async () => {
        let body = { dealName: newDeal };
        try {
            if (!selectedDeal) {
                console.error('No deal selected for editing.');
                return;
            }

            const token = { Authorization: `token ${localStorage.getItem('token')}` };
            const response = await Services.editDeal(selectedDeal.productDealId, JSON.stringify(body), token);
            if (response.Status === 1) {
                fetchHomeDeals();
                handleCloseModal();
                Success(response.Message);
            } else {
                console.error('Error updating deal:', response.Message);
            }
        } catch (error) {
            console.error('Error updating deal:', error);
        }
    };

    const handleDeleteDeal = (productDealId) => {
        setDeleteDealId(productDealId);
        setDeleteConfirmation(true);
    };

    const handleConfirmDelete = async () => {
        try {
            const token = { Authorization: `token ${localStorage.getItem('token')}` };
            const response = await Services.deleteDeal(deleteDealId, token);
            if (response.Status === 1) {
                fetchHomeDeals();
                Success(response.Message);
                setDeleteConfirmation(false);
            } else {
                console.error('Error deleting deal:', response.Message);
            }
        } catch (error) {
            console.error('Error deleting deal:', error);
        }
    };

    const handleCancelDelete = () => {
        setDeleteConfirmation(false);
        setDeleteDealId(null);
    };

    const handleMenuOpen = (event, productDealId) => {
        event.stopPropagation();
        setMenuAnchorEl(event.currentTarget);
        setSelectedRowId(productDealId);

        // Check if the selected productDealId is 12 and set delete visibility
        setIsDeleteVisible(productDealId !== 12);
    };

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
        setSelectedRowId(null);
    };

    const handleEdit = () => {
        const deal = homeDeals.find(d => d.productDealId === selectedRowId);
        handleOpenModal(deal);
        handleMenuClose();
    };

    const handleDelete = () => {
        handleDeleteDeal(selectedRowId);
        handleMenuClose();
    };

    const columns = [
        { field: 'dealName', headerName: 'Product Deal', width: 700 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 600,
            renderCell: (params) => (
                <div>
                    <IconButton
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={(event) => handleMenuOpen(event, params.row.productDealId)}
                    >
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        id="simple-menu"
                        anchorEl={menuAnchorEl}
                        keepMounted
                        open={Boolean(menuAnchorEl)}
                        onClose={handleMenuClose}
                        sx={{ boxShadow: 'none' }}
                    >
                        {isDeleteVisible && (<MenuItem onClick={handleEdit}>Edit</MenuItem>)}
                        {/* Conditionally render the Delete option based on state */}
                        {isDeleteVisible && (
                            <MenuItem onClick={handleDelete}>Delete</MenuItem>
                        )}
                    </Menu>
                </div>
            ),
        },
    ];

    return (
        <Layout Active={"Product Deal"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Product Deals</title>
            </Helmet>
            <div className={CommonClasses["Container"]}>
                <h3 className={ProductClasses['main-header']}>Product Deals<span className={ProductClasses['count-of-users']}>({homeDeals.length})</span></h3>
                <div className={CommonClasses['control-btns']}>
                    <Button style={{ marginBottom: '20px' }}
                        className={CommonClasses['button-for-add']}
                        onClick={() => handleOpenModal(null)}
                    >
                        <BiPlus size={20} /> Add Product Deal
                    </Button>
                </div>
                <div>
                    {loading ? (
                        <SkeletonLoad
                            TableHead={["Product Deal", "Actions"]}
                        />
                    ) : homeDeals.length > 0 ? (
                        <div className={CommonClasses["Table"]} style={{ maxHeight: 600 }}>
                            <DataGrid
                                rows={homeDeals}
                                columns={columns}
                                pageSize={10}
                                rowsPerPageOptions={[10, 25, 50, { value: -1, label: 'All' }]}
                                pagination
                                getRowId={(row) => row.productDealId}
                                checkboxSelection
                                onSelectionModelChange={(newSelection) => {
                                    setSelectedRows(newSelection);
                                }}
                            />
                            {selectedRows.length > 0 && (
                                <div>
                                    <p>Selected Product Deals: {selectedRows.join(', ')}</p>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div>
                            <p>No Product Deals Found</p>
                        </div>
                    )}
                </div>
            </div>

            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <div className={ProductClasses.modal}>
                    <h2 id="modal-title">{selectedDeal ? 'Edit Product Deal' : 'Add Product Deal'}</h2>
                    <TextField
                        label="Product Deal"
                        value={newDeal}
                        onChange={(e) => setNewDeal(e.target.value)}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                    />
                    <button className={ProductClasses['edit-btn']} onClick={selectedDeal ? handleEditDeal : handleAddDeal}>{selectedDeal ? 'Edit' : 'Add'}</button>
                    <button className={ProductClasses['del-btn']} onClick={handleCloseModal}>Cancel</button>
                </div>
            </Modal>

            <Dialog
                open={deleteConfirmation}
                onClose={handleCancelDelete}
                aria-labelledby="delete-dialog-title"
                aria-describedby="delete-dialog-description"
            >
                <DialogTitle id="delete-dialog-title">Confirm Deletion</DialogTitle>
                <DialogContent>
                    <DialogContentText id="delete-dialog-description">
                        Are you sure you want to delete this product deal?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelDelete} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmDelete} color="secondary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </Layout>
    );
};

export default ProductDeal;
