import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Breadcrumbs, Link, Typography, Button, Stack, Tabs, Tab, Box } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { MdOutlineArrowBackIos } from "react-icons/md";
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import Layout from 'Components/Layout';
import CommonClasses from 'Styles/Common.module.css';
import UserClasses from '../Users.module.css';
import ViewUserClasses from './ViewUsers.module.css';
import UserOverviewTable from './UserOverviewTable';
import UserAddressTable from './UserAddressTable';
import UserDocuments from './UserDocuments';
import UserOrders from './UserOrders';
import AddUserClasses from '../addUser/AddUser.module.css'
import { Services } from 'Services';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
};

const ViewUsers = () => {
    const navigate = useNavigate();
    const { userId } = useParams();
    const { state } = useLocation();
    const [tabIndex, setTabIndex] = useState(0);
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" onClick={() => navigate('/')}>
            Home
        </Link>,
        <Link underline="hover" key="2" color="inherit" onClick={() => navigate('/users')}>
            Users
        </Link>,
        <Typography key="3" color="text.primary">
            View User
        </Typography>,
    ];

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const token = { Authorization: `Bearer ${localStorage.getItem('token')}` };
                const response = await Services.getUserDetails(userId, token);
                setUser(response.user); // Adjust this line based on your API response structure
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchUserDetails();
    }, [userId]);

    return (
        <Layout Active={'Users'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Users</title>
            </Helmet>
            <div className={AddUserClasses['main-of-tables']}>
                <div className={AddUserClasses['new-main-div']}>
                    <div className={ViewUserClasses['main-on-ctrl-btns']}>
                        <div className={CommonClasses['control-btns']}>
                            <Button className={CommonClasses["button-for-add"]} onClick={() => navigate(-1)}><MdOutlineArrowBackIos />Back</Button>
                        </div>
                        <Stack spacing={2}>
                            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                                {breadcrumbs}
                            </Breadcrumbs>
                        </Stack>
                    </div>
                </div>
                {user ? (
                    <div>
                        <div className={ViewUserClasses['main-name']}>
                            <div className={ViewUserClasses['inside-leftname']}>
                                <h1 className={ViewUserClasses['user-fullname']}>{user.firstName}{user.lastName}</h1>
                                <p style={{ backgroundColor: user.isDocumentsVerified === 1 ? '#79f4a3' : '#fef987', color: 'black' }} className={ViewUserClasses['user-para-stat']}>{user.isDocumentsVerified === 1 ? 'Verified' : user.isDocumentsVerified === 0 ? 'Not Verified' : 'NA'}</p>
                            </div>
                            <div className={ViewUserClasses['inside-rightname']}>
                                <button onClick={() => navigate(`/edit-users/${userId}`)}>Edit</button>
                            </div>
                        </div>
                        <div>
                        <h4>User ID:{user.userId}</h4>
                        </div>
                        <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                            <Tabs className={ViewUserClasses['tabs-main-head']} value={tabIndex} onChange={handleTabChange} aria-label="user details tabs">
                                <Tab className={ViewUserClasses['tabs-main-sub-head']} label="Overview" />
                                <Tab label="Addresses" />
                                <Tab label="Documents" />
                                <Tab label="Orders" />
                            </Tabs>
                            <TabPanel value={tabIndex} index={0}>
                                <UserOverviewTable user={user} />
                            </TabPanel>
                            <TabPanel value={tabIndex} index={1}>
                                <UserAddressTable user={user} />
                            </TabPanel>
                            <TabPanel value={tabIndex} index={2}>
                                <UserDocuments user={user} />
                            </TabPanel>
                            <TabPanel value={tabIndex} index={3}>
                                <UserOrders user={user} />
                            </TabPanel>
                        </Box>
                    </div>
                ) : (
                    <div>
                        <p>No User Found</p>
                    </div>
                )}
            </div>
        </Layout>
    );
};

export default ViewUsers;
