// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EditProduct_main-prod-over-btn__5HSw\\+{\n    /* border: 1px solid red; */\n    display: flex;\n    justify-content: end;\n}\n\n.EditProduct_update-prod-overview-btn__so1jc{\n    /* border: 1px solid red; */\n    padding: 5px;\n    background-color: #23232A;\n    color: white;\n    border-radius: 4px;\n    width: 130px;\n}", "",{"version":3,"sources":["webpack://./src/pages/Product/editProduct/EditProduct.module.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,aAAa;IACb,oBAAoB;AACxB;;AAEA;IACI,2BAA2B;IAC3B,YAAY;IACZ,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,YAAY;AAChB","sourcesContent":[".main-prod-over-btn{\n    /* border: 1px solid red; */\n    display: flex;\n    justify-content: end;\n}\n\n.update-prod-overview-btn{\n    /* border: 1px solid red; */\n    padding: 5px;\n    background-color: #23232A;\n    color: white;\n    border-radius: 4px;\n    width: 130px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main-prod-over-btn": "EditProduct_main-prod-over-btn__5HSw+",
	"update-prod-overview-btn": "EditProduct_update-prod-overview-btn__so1jc"
};
export default ___CSS_LOADER_EXPORT___;
