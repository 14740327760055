import Layout from 'Components/Layout';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import CommonClasses from 'Styles/Common.module.css';
import ProductClasses from '../Product/Products.module.css';
import { useNavigate } from 'react-router-dom';
import { Breadcrumbs, Button, Grid, Link, Stack, TextField, Typography } from '@mui/material';
import { Services } from 'Services';
import { Success, Error } from 'Util/Toast'; // Assuming you have an Error toast component
import { MdOutlineArrowBackIos } from 'react-icons/md';
import ViewUserClasses from '../users/viewUsers/ViewUsers.module.css'
import AddUserClasses from '../users/addUser/AddUser.module.css'
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import FaqClasses from '../faq/Faq.module.css';



const CreateHelp = () => {
    const navigate = useNavigate();
    const [heading, setHeading] = useState('');
    const [question, setQuestion] = useState('');
    const [answer, setAnswer] = useState('');

    const validateForm = () => {
        if (!heading || !question || !answer) {
            Error('Please fill in all fields.');
            return false;
        }
        return true;
    };

    const addHelp = () => {
        if (!validateForm()) return;

        // Retrieve the token from wherever it's stored in your application
        const token = { Authorization: `token ${localStorage.getItem('token')}` };

        // Construct the payload object with heading as a string
        // const payload = heading;

        let body = {
            heading: heading,
            solutions: [
                {
                    Question: question,
                    Answer: answer,
                },
            ],

        }

        // Construct the solutions array
        // const solutions = [
        //     {
        //         Question: question,
        //         Answer: answer
        //     }
        // ];

        // Call addHelp API with the data and token in headers
        Services.addHelp(JSON.stringify(body), token)
            .then((response) => {
                // Optionally, you can navigate to another page after successful addition
                navigate('/help');
                Success(response.Message);
            })
            .catch((error) => {
                // Handle error if necessary
                console.error('Error adding help:', error);
            });
    };

    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" onClick={() => navigate('/help')}>
            Help
        </Link>,
        <Typography key="2" color="text.primary">
            Create Help
        </Typography>,
    ];

    return (
        <Layout Active={'Help'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Create Help</title>
            </Helmet>
            <div className={CommonClasses['Container']}>
                <div className={AddUserClasses['new-main-config-div']}>
                    <div className={ViewUserClasses['main-on-ctrl-btns']}>
                        <div className={CommonClasses['control-btns']}>
                            <Button className={CommonClasses["button-for-add"]} onClick={() => navigate(-1)}><MdOutlineArrowBackIos />Back</Button>
                        </div>
                        <Stack spacing={2}>
                            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                                {breadcrumbs}
                            </Breadcrumbs>
                        </Stack>
                    </div>
                    <div className={AddUserClasses['inside-new-main-btn']}>
                        <button style={{ width: '130px' }} className={FaqClasses['add-button']} onClick={addHelp}>
                            Save Changes
                        </button>
                    </div>
                </div>
                <div style={{marginTop: '40px'}} className={FaqClasses['create-main-head']} data-aos="zoom-in-down">
                    <div className={FaqClasses['main-h3-head']}>
                        <h3 className={FaqClasses['main-header']}>Create Help</h3>
                    </div>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={11.8}>
                            <TextField
                                className={FaqClasses['main-faq-bar']}
                                label="Heading"
                                variant="outlined"
                                fullWidth
                                value={heading}
                                onChange={(e) => setHeading(e.target.value)}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={11.8}>
                            <TextField
                                className={FaqClasses['main-faq-bar']}
                                label="Question"
                                variant="outlined"
                                fullWidth
                                value={question}
                                onChange={(e) => setQuestion(e.target.value)}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={11.8}>
                            <TextField
                                className={FaqClasses['main-faq-bar']}
                                label="Answer"
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={4}
                                value={answer}
                                onChange={(e) => setAnswer(e.target.value)}
                                required
                            />
                        </Grid>
                    </Grid>
                </div>
            </div>
        </Layout>
    );
};

export default CreateHelp;
