import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Switch, FormControlLabel, Grid, MenuItem } from '@mui/material';
import { BiPlus } from 'react-icons/bi';
import { Country, State } from 'country-state-city';
import ViewUserClasses from '../viewUsers/ViewUsers.module.css';
import AddUserClasses from './AddUser.module.css';
import CommonClasses from '../../../Styles/Common.module.css';

const AddUserAddress = ({ userData, setUserData, errors, validate }) => {
    const [open, setOpen] = useState(false); // State to control dialog open/close
    const [defaultAddress, setDefaultAddress] = useState(false); // State for default address toggle
    const [countries, setCountries] = useState([]); // State to store countries list
    const [states, setStates] = useState([]); // State to store states list based on selected country
    const [enteredAddress, setEnteredAddress] = useState(null); // State to store entered address details
    const [addressTypeDisplay, setAddressTypeDisplay] = useState(''); // State for address type display value

    useEffect(() => {
        // Load countries list on component mount
        setCountries(Country.getAllCountries());
    }, []);

    // Function to handle dialog open
    const handleClickOpen = () => {
        if (userData.countryCode) {
            // If user already selected a country, load states for that country
            setStates(State.getStatesOfCountry(userData.countryCode));
        }
        setOpen(true); // Open the dialog
    };

    // Function to handle dialog close
    const handleClose = () => {
        setOpen(false); // Close the dialog
    };

    // Function to handle save action
    const handleSave = () => {
        setEnteredAddress({ ...userData }); // Save entered address details
        setOpen(false); // Close the dialog
    };

    // Function to handle default address toggle
    const handleDefaultChange = () => {
        setDefaultAddress(!defaultAddress); // Toggle default address state
    };

    // Function to handle text field changes
    const handleChange = (event) => {
        const { name, value } = event.target;

        // If the field is address type, map it to the corresponding value
        let mappedValue = value;
        if (name === 'addressType') {
            mappedValue = value === 'Home' ? 1 : 2;
            setAddressTypeDisplay(value); // Set the display value for address type
        }

        setUserData(prevUserData => ({
            ...prevUserData,
            [name]: mappedValue
        }));
        validate(name, value); // Validate the changed field
    };

    // Function to handle country selection change
    const handleCountryChange = (event) => {
        const selectedCountryCode = event.target.value;
        const selectedCountry = countries.find(country => country.isoCode === selectedCountryCode);

        setUserData(prevUserData => ({
            ...prevUserData,
            country: selectedCountry.name,
            countryCode: selectedCountry.isoCode,
            state: '', // Reset state when country changes
            stateCode: ''
        }));

        setStates(State.getStatesOfCountry(selectedCountryCode)); // Load states for selected country
    };

    // Function to handle state selection change
    const handleStateChange = (event) => {
        const selectedStateCode = event.target.value;
        const selectedState = states.find(state => state.isoCode === selectedStateCode);

        setUserData(prevUserData => ({
            ...prevUserData,
            state: selectedState.name,
            stateCode: selectedState.isoCode
        }));
    };

    return (
        <div>
            <div className={AddUserClasses['main-user-address']}>
                <div className={AddUserClasses['main-userleft-address']}>
                    <h3 className={AddUserClasses['main-address-header']}>User Address</h3>
                </div>
                <div className={AddUserClasses['main-userright-address']}>
                    <div className={CommonClasses['control-btns']}>
                        <Button
                            style={{ backgroundColor: 'white' }}
                            className={CommonClasses['button-for-add']}
                            onClick={handleClickOpen}
                        >
                            <BiPlus size={20} /> Add Address
                        </Button>
                    </div>
                </div>
                {/* Dialog for adding address */}
                <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                    <DialogTitle>Add Address</DialogTitle>
                    <DialogContent>
                        {/* Switch for default address */}
                        <FormControlLabel
                            control={<Switch checked={defaultAddress} onChange={handleDefaultChange} />}
                            label="Make default"
                            style={{ float: 'right' }}
                        />
                        <Grid container spacing={2}>
                            {/* Text fields for address details */}
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    id="firstName"
                                    name="firstName"
                                    label="First name"
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    value={userData.firstName || ''}
                                    onChange={handleChange}
                                    error={!!errors.firstName}
                                    helperText={errors.firstName}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    id="lastName"
                                    name="lastName"
                                    label="Last name"
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    value={userData.lastName || ''}
                                    onChange={handleChange}
                                    error={!!errors.lastName}
                                    helperText={errors.lastName}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    id="company"
                                    name="company"
                                    label="Company"
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    value={userData.company || ''}
                                    onChange={handleChange}
                                    error={!!errors.company}
                                    helperText={errors.company}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    id="deliveryAddress"
                                    name="deliveryAddress"
                                    label="Delivery Address (Apartment, flat/ house no)"
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    value={userData.deliveryAddress || ''}
                                    onChange={handleChange}
                                    error={!!errors.deliveryAddress}
                                    helperText={errors.deliveryAddress}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    id="mobileNumber"
                                    name="mobileNumber"
                                    label="Mobile Number"
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    value={userData.mobileNumber || ''}
                                    onChange={handleChange}
                                    error={!!errors.mobileNumber}
                                    helperText={errors.mobileNumber}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    select
                                    required
                                    id="country"
                                    name="country"
                                    label="Country"
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    value={userData.countryCode || ''}
                                    onChange={handleCountryChange}
                                    error={!!errors.country}
                                    helperText={errors.country}
                                >
                                    {countries.map((country) => (
                                        <MenuItem key={country.isoCode} value={country.isoCode}>
                                            {country.name} ({country.isoCode})
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    select
                                    required
                                    id="state"
                                    name="state"
                                    label="State"
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    value={userData.stateCode || ''}
                                    onChange={handleStateChange}
                                    error={!!errors.state}
                                    helperText={errors.state}
                                    disabled={!states.length}
                                >
                                    {states.map((state) => (
                                        <MenuItem key={state.isoCode} value={state.isoCode}>
                                            {state.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    id="city"
                                    name="city"
                                    label="City"
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    value={userData.city || ''}
                                    onChange={handleChange}
                                    error={!!errors.city}
                                    helperText={errors.city}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    id="area"
                                    name="area"
                                    label="Area"
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    value={userData.area || ''}
                                    onChange={handleChange}
                                    error={!!errors.area}
                                    helperText={errors.area}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    id="pincode"
                                    name="pincode"
                                    label="Pin Code"
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    value={userData.pincode || ''}
                                    onChange={handleChange}
                                    error={!!errors.pincode}
                                    helperText={errors.pincode}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    id="flatNo"
                                    name="flatNo"
                                    label="Flat No"
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    value={userData.flatNo || ''}
                                    onChange={handleChange}
                                    error={!!errors.flatNo}
                                    helperText={errors.flatNo}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    select
                                    required
                                    id="addressType"
                                    name="addressType"
                                    label="Address Type"
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    value={addressTypeDisplay || ''}
                                    onChange={handleChange}
                                    error={!!errors.addressType}
                                    helperText={errors.addressType}
                                >
                                    <MenuItem value="Home">Home</MenuItem>
                                    <MenuItem value="Office">Office</MenuItem>
                                </TextField>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions className={AddUserClasses['useraddress-mod']}>
                        <button className={AddUserClasses['useraddress-cancel-mod']} onClick={handleClose}>Cancel</button>
                        <button className={AddUserClasses['useraddress-save-mod']} onClick={handleSave} color="primary">Save</button>
                    </DialogActions>
                </Dialog>
            </div>
            {/* Display entered details after closing the dialog */}
            {enteredAddress && (
                <div className={AddUserClasses['selected-user-details']}>
                    <table className={AddUserClasses['user-table']}>
                        <thead>
                            <tr>
                                <th colSpan="2" className={AddUserClasses['table-header']}>Address Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className={AddUserClasses['table-label']}>First Name:</td>
                                <td className={AddUserClasses['table-value']}>{enteredAddress.firstName}</td>
                            </tr>
                            <tr>
                                <td className={AddUserClasses['table-label']}>Last Name:</td>
                                <td className={AddUserClasses['table-value']}>{enteredAddress.lastName}</td>
                            </tr>
                            <tr>
                                <td className={AddUserClasses['table-label']}>Company:</td>
                                <td className={AddUserClasses['table-value']}>{enteredAddress.company}</td>
                            </tr>
                            <tr>
                                <td className={AddUserClasses['table-label']}>Mobile Number:</td>
                                <td className={AddUserClasses['table-value']}>{enteredAddress.mobileNumber}</td>
                            </tr>
                            <tr>
                                <td className={AddUserClasses['table-label']}>Delivery Address:</td>
                                <td className={AddUserClasses['table-value']}>{enteredAddress.deliveryAddress}</td>
                            </tr>
                            <tr>
                                <td className={AddUserClasses['table-label']}>City:</td>
                                <td className={AddUserClasses['table-value']}>{enteredAddress.city}</td>
                            </tr>
                            <tr>
                                <td className={AddUserClasses['table-label']}>State:</td>
                                <td className={AddUserClasses['table-value']}>{enteredAddress.state}</td>
                            </tr>
                            <tr>
                                <td className={AddUserClasses['table-label']}>Area:</td>
                                <td className={AddUserClasses['table-value']}>{enteredAddress.area}</td>
                            </tr>
                            <tr>
                                <td className={AddUserClasses['table-label']}>Pin Code:</td>
                                <td className={AddUserClasses['table-value']}>{enteredAddress.pincode}</td>
                            </tr>
                            <tr>
                                <td className={AddUserClasses['table-label']}>Flat No:</td>
                                <td className={AddUserClasses['table-value']}>{enteredAddress.flatNo}</td>
                            </tr>
                            <tr>
                                <td className={AddUserClasses['table-label']}>Address Type:</td>
                                <td className={AddUserClasses['table-value']}>{enteredAddress.addressType === 1 ? 'Home' : 'Office'}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default AddUserAddress;
