import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { Services } from 'Services';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Skeleton from '@mui/material/Skeleton';
import YoutubeEmbed from 'react-youtube-embed';
import cardimg from '../../../Assets/box-img-med.png';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { styled } from '@mui/material/styles';
import ViewUserClasses from '../../users/viewUsers/ViewUsers.module.css';

const StyledCard = styled(Card)(({ theme }) => ({
    width: '200px',
    height: '200px',
    margin: '0.5rem',
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
        boxShadow: theme.shadows[6],
    },
}));

const StyledPlayIcon = styled(PlayCircleOutlineIcon)(({ theme }) => ({
    fontSize: '70px',
    display: 'block',
    margin: 'auto',
    '&:hover': {
        color: theme.palette.primary.main,
        transform: 'scale(1.1)',
    },
}));

const ProductGallery = () => {
    const { productId } = useParams();
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedVideo, setSelectedVideo] = useState(null); // State to track selected video URL

    useEffect(() => {
        const fetchProductDetails = async () => {
            try {
                const token = { Authorization: `Bearer ${localStorage.getItem('token')}` };
                const response = await Services.getProductDetails(productId, token);
                if (response.Status === 1) {
                    // Parse productVideo string to JSON array
                    const productData = { ...response.product, productVideo: JSON.parse(response.product.productVideo) };
                    setProduct(productData);
                } else {
                    setError('Failed to fetch product details.');
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchProductDetails();
    }, [productId]);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const playVideo = (videoUrl) => {
        setSelectedVideo(videoUrl);
    };

    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                {[...Array(3)].map((_, index) => (
                    <Card key={index} style={{ width: '200px', height: '300px', margin: '0.5rem' }}>
                        <CardContent>
                            <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
                            <Skeleton animation="wave" height={10} width="80%" />
                        </CardContent>
                        <Skeleton variant="rectangular" height={200} />
                        <CardContent>
                            <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
                            <Skeleton animation="wave" height={10} width="80%" />
                        </CardContent>
                    </Card>
                ))}
            </div>
        );
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!product) {
        return <div>No product found.</div>;
    }

    return (
        <div>
            <table className={ViewUserClasses['user-table']} style={{ width: '100%' }}>
                <thead>
                    <tr>
                        <th className={ViewUserClasses['table-header']} colSpan="2" style={{ textAlign: 'start', paddingLeft: '15px' }}>Images</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                            {product.productImage && product.productImage.length > 0 ? (
                                product.productImage.map((image, index) => (
                                    <div key={index} style={{ margin: '0.5rem' }}>
                                        <StyledCard onClick={handlePopoverOpen}>
                                            <CardMedia
                                                component="img"
                                                height="140"
                                                image={image}
                                                alt={`Product ${index + 1}`}
                                            />
                                            <CardContent>
                                                <Typography variant="body2" color="text.secondary" component="p">
                                                    {product.productName} {product.metricValue} {product.metricType}
                                                </Typography>
                                            </CardContent>
                                        </StyledCard>
                                    </div>
                                ))
                            ) : (
                                'No images available'
                            )}
                        </td>
                    </tr>
                </tbody>
            </table>
            <table className={ViewUserClasses['user-table']} style={{ width: '100%' }}>
                <thead>
                    <tr>
                        <th className={ViewUserClasses['table-header']} colSpan="2" style={{ textAlign: 'start', paddingLeft: '15px' }}>Videos</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                            {product.productVideo && Array.isArray(product.productVideo) && product.productVideo.length > 0 ? (
                                product.productVideo.map((videoUrl, index) => (
                                    <div key={index} style={{ margin: '0.5rem' }}>
                                        <StyledCard onClick={() => playVideo(videoUrl)}>
                                            <CardMedia>
                                                <YoutubeEmbed width="100%" height="100%" id={videoUrl} />
                                            </CardMedia>
                                            <CardContent>
                                                <Typography variant="body2" color="text.secondary" component="p" align="left">
                                                    Video {index + 1}
                                                </Typography>
                                            </CardContent>
                                        </StyledCard>
                                    </div>
                                ))
                            ) : (
                                'No videos available'
                            )}
                        </td>
                    </tr>
                </tbody>
            </table>

            {/* Video Player */}
            {/* Removed the separate video player section */}
        </div>
    );
};

export default ProductGallery;
