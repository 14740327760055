import React from 'react';
import { TextField } from '@mui/material';
import AddUserClasses from './AddUser.module.css';

const AddUserOverview = ({ userData, setUserData, errors, setErrors, validate }) => {

    const handleChange = (event) => {
        const { name, value } = event.target;
        setUserData(prevUserData => ({
            ...prevUserData,
            [name]: value
        }));

        // Perform validation
        if (name === 'password' || name === 'confirmPassword') {
            const password = name === 'password' ? value : userData.password;
            const confirmPassword = name === 'confirmPassword' ? value : userData.confirmPassword;
            
            if (password !== confirmPassword) {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    confirmPassword: 'Passwords do not match'
                }));
            } else {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    confirmPassword: ''
                }));
            }
        }

        // Remove error when user starts typing and validate
        validate(name, value);
    };

    return (
        <div>
            <table className={AddUserClasses['user-table']}>
                <thead>
                    <tr>
                        <th colSpan="2" className={AddUserClasses['table-header']}>About</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className={AddUserClasses['table-label']}>Company</td>
                        <td className={AddUserClasses['table-value']}>
                            <TextField
                                required
                                id="company"
                                name="company"
                                value={userData.company || ''}
                                onChange={handleChange}
                                variant="outlined"
                                margin="dense"
                                className={AddUserClasses['table-textfield-all']}
                                error={!!errors.company}
                                helperText={errors.company}
                                placeholder="Enter your company name *"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className={AddUserClasses['table-label']}>First Name</td>
                        <td className={AddUserClasses['table-value']}>
                            <TextField
                                required
                                id="firstName"
                                name="firstName"
                                value={userData.firstName || ''}
                                onChange={handleChange}
                                variant="outlined"
                                margin="dense"
                                className={AddUserClasses['table-textfield-all']}
                                error={!!errors.firstName}
                                helperText={errors.firstName}
                                placeholder="Enter your first name *"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className={AddUserClasses['table-label']}>Last Name</td>
                        <td className={AddUserClasses['table-value']}>
                            <TextField
                                required
                                id="lastName"
                                name="lastName"
                                value={userData.lastName || ''}
                                onChange={handleChange}
                                variant="outlined"
                                margin="dense"
                                className={AddUserClasses['table-textfield-all']}
                                error={!!errors.lastName}
                                helperText={errors.lastName}
                                placeholder="Enter your last name *"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className={AddUserClasses['table-label']}>Email</td>
                        <td className={AddUserClasses['table-value']}>
                            <TextField
                                required
                                id="email"
                                name="email"
                                value={userData.email || ''}
                                onChange={handleChange}
                                variant="outlined"
                                margin="dense"
                                className={AddUserClasses['table-textfield-all']}
                                error={!!errors.email}
                                helperText={errors.email}
                                placeholder="Enter your email *"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className={AddUserClasses['table-label']}>Mobile Number</td>
                        <td className={AddUserClasses['table-value']}>
                            <TextField
                                required
                                id="mobileNumber"
                                name="mobileNumber"
                                value={userData.mobileNumber || ''}
                                onChange={handleChange}
                                variant="outlined"
                                margin="dense"
                                className={AddUserClasses['table-textfield-all']}
                                error={!!errors.mobileNumber}
                                helperText={errors.mobileNumber}
                                placeholder="Enter your mobile number *"
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
            <table className={AddUserClasses['user-table']}>
                <thead>
                    <tr>
                        <th colSpan="2" className={AddUserClasses['table-header']}>Security</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className={AddUserClasses['table-label']}>Password</td>
                        <td className={AddUserClasses['table-value']}>
                            <TextField
                                required
                                id="password"
                                name="password"
                                type="password"
                                value={userData.password || ''}
                                onChange={handleChange}
                                variant="outlined"
                                margin="dense"
                                className={AddUserClasses['table-textfield-all']}
                                error={!!errors.password}
                                helperText={errors.password}
                                placeholder="Enter your password *"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className={AddUserClasses['table-label']}>Confirm Password</td>
                        <td className={AddUserClasses['table-value']}>
                            <TextField
                                required
                                id="confirmPassword"
                                name="confirmPassword"
                                type="password"
                                value={userData.confirmPassword || ''}
                                onChange={handleChange}
                                variant="outlined"
                                margin="dense"
                                className={AddUserClasses['table-textfield-all']}
                                error={!!errors.confirmPassword}
                                helperText={errors.confirmPassword}
                                placeholder="Confirm Password *"
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default AddUserOverview;
