import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Button, Step, StepLabel, Stepper, Breadcrumbs, Link, Typography, Stack } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { MdOutlineArrowBackIos } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import Layout from 'Components/Layout';
import ViewUserClasses from '../../users/viewUsers/ViewUsers.module.css';
import CommonClasses from '../../../Styles/Common.module.css';
import AddUserClasses from '../../users/addUser/AddUser.module.css';
import { Services } from 'Services';
import AddOrderGeneral from './AddOrderGeneral';
import AddOrderCustomerAndShipping from './AddOrderCustomerAndShipping';
import AddOrderProducts from './AddOrderProducts';
import { Success } from 'Util/Toast';

const AddOrder = () => {
    const navigate = useNavigate();
    const [activeStep, setActiveStep] = useState(0);
    const [userData, setUserData] = useState({
        paymentType: '', // Initialize with an empty string or default value
        selectedUserDetails: null, // Initialize with null or appropriate default
        deliveryAddress: '', // Initialize with an empty string or default value
        selectedProductDetails: [], // Initialize with an empty array
        quantities: {}, // Initialize with an empty object
    });
    const [errors, setErrors] = useState({});

    const steps = ['General', 'Customer & Shipping', 'Products'];

    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" onClick={() => navigate('/')}>
            Home
        </Link>,
        <Link underline="hover" key="2" color="inherit" onClick={() => navigate('/orders')}>
            Orders
        </Link>,
        <Typography key="3" color="text.primary">
            Add Order
        </Typography>,
    ];

    const validate = (name, value) => {
        setErrors(prevErrors => {
            const newErrors = { ...prevErrors };
            if (!value) {
                newErrors[name] = `${name} is required`;
            } else {
                delete newErrors[name];
            }
            return newErrors;
        });
    };

    const validateForm = () => {
        const newErrors = {};
        if (activeStep === 0) {
            if (!userData.paymentType) newErrors.paymentType = 'Payment Type is required *';
        }
        if (activeStep === 1) {
            if (!userData.selectedUserDetails) newErrors.selectedUserDetails = 'Customer Details are required';
            if (!userData.deliveryAddress) newErrors.deliveryAddress = 'Shipping Address is required';
        }
        if (activeStep === 2) {
            if (!userData.selectedProductDetails || userData.selectedProductDetails.length === 0) {
                newErrors.products = 'Please select at least one product';
            }
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleNext = () => {
        if (validateForm()) {
            setActiveStep(prevActiveStep => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    const handleSaveChanges = async () => {
        try {
            // Validate all steps before saving changes
            if (!validateForm()) return;

            let selectedProductDetails = userData.selectedProductDetails || []; // Make sure selectedProductDetails exists
            let quantities = userData.quantities || {}; // Make sure quantities exists

            const productsPayload = selectedProductDetails.map(product => ({
                productPrice: product.productDiscountPrice, // Assuming productDiscountPrice is the selling price
                productDiscountPrice: product.productDiscountPrice,
                qty: quantities[product.productId] || 0, // If quantity is not available, default to 0
                productId: product.productId
            }));

            // Prepare the payload data
            const payload = {
                paymentType: userData.paymentType,
                userDetails: userData.selectedUserDetails,
                deliveryAddress: {...userData.deliveryAddress,addressType:Number(userData.deliveryAddress?.addressType)},
                products: productsPayload,
                grandTotal: userData.grandTotal, // Include grandTotal from userData
                subtotal: userData.subtotal // Include subtotal from userData
            };

            // Call addOrder API
            const token = { Authorization: `token ${localStorage.getItem('token')}` };
            const response = await Services.addOrder(payload, token);
            console.log('Order created:', response); // Log the response from the API
            if (response.Status === 1) {
                Success(response.Message);
                navigate('/orders');
            } else if( response.Status===0){
                Error(response.Message);
            }
        } catch (error) {
            console.error('Error creating order:', error);
        }
    };

    const getStepContent = (stepIndex) => {
        switch (stepIndex) {
            case 0:
                return <AddOrderGeneral userData={userData} setUserData={setUserData} errors={errors} validate={validate} />;
            case 1:
                return <AddOrderCustomerAndShipping userData={userData} setUserData={setUserData} errors={errors} validate={validate} />;
            case 2:
                return <AddOrderProducts userData={userData} setUserData={setUserData} errors={errors} />;
            default:
                return 'Unknown stepIndex';
        }
    };

    return (
        <Layout Active={'Orders'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Add Order</title>
            </Helmet>
            <div className={AddUserClasses['main-of-tables']}>
                <div className={AddUserClasses['new-main-div']}>
                    <div className={ViewUserClasses['main-on-ctrl-btns']}>
                        <div className={CommonClasses['control-btns']}>
                            <Button className={CommonClasses["button-for-add"]} onClick={() => navigate(-1)}>
                                <MdOutlineArrowBackIos />Back
                            </Button>
                        </div>
                        <Stack spacing={2}>
                            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                                {breadcrumbs}
                            </Breadcrumbs>
                        </Stack>
                    </div>
                </div>
                <div className={AddUserClasses['main-right-add-btns']}>
                    {activeStep !== 0 && (
                        <button className={AddUserClasses['main-right-cancel-btns']} onClick={handleBack} style={{ marginRight: 10 }}>
                            Back
                        </button>
                    )}
                    {activeStep !== steps.length - 1 ? (
                        <button className={AddUserClasses['next-stepper-btn']} variant="contained" onClick={handleNext}>
                            Continue to next step
                        </button>
                    ) : (
                        <button className={AddUserClasses['main-right-save-btns']} onClick={handleSaveChanges}>
                            Save Changes
                        </button>
                    )}
                </div>
                <Stepper activeStep={activeStep}>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <div className={AddUserClasses['main-btn-div']}>
                    {getStepContent(activeStep)}
                    {errors.paymentType && activeStep === 0 && (
                        <Typography color="error" variant="body2">
                            {errors.paymentType}
                        </Typography>
                    )}
                    {errors.selectedUserDetails && activeStep === 1 && (
                        <Typography color="error" variant="body2">
                            {errors.selectedUserDetails}
                        </Typography>
                    )}
                    {errors.deliveryAddress && activeStep === 1 && (
                        <Typography color="error" variant="body2">
                            {errors.deliveryAddress}
                        </Typography>
                    )}
                    {errors.products && activeStep === 2 && (
                        <Typography color="error" variant="body2">
                            {errors.products}
                        </Typography>
                    )}
                </div>
            </div>
        </Layout>
    );
};

export default AddOrder;
