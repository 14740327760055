// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AccountSettings_password-btn__REPe6{\n    width: -moz-fit-content;\n    width: fit-content;\n}\n\n\n.AccountSettings_panel1a-header__wlpC6{\n    /* border: 1px solid red !important; */\n    background-color: #3096eb !important;\n}\n\n.AccountSettings_panel1a-header__wlpC6 h4{\n    color: white;\n}\n\n", "",{"version":3,"sources":["webpack://./src/pages/accountsettings/AccountSettings.module.css"],"names":[],"mappings":"AAAA;IACI,uBAAkB;IAAlB,kBAAkB;AACtB;;;AAGA;IACI,sCAAsC;IACtC,oCAAoC;AACxC;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".password-btn{\n    width: fit-content;\n}\n\n\n.panel1a-header{\n    /* border: 1px solid red !important; */\n    background-color: #3096eb !important;\n}\n\n.panel1a-header h4{\n    color: white;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"password-btn": "AccountSettings_password-btn__REPe6",
	"panel1a-header": "AccountSettings_panel1a-header__wlpC6"
};
export default ___CSS_LOADER_EXPORT___;
