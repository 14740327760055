import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ViewUserClasses from '../../users/viewUsers/ViewUsers.module.css';
import { Services } from 'Services';
import { Skeleton } from '@mui/material';

const OrderCustomerShipping = () => {
    const { orderId } = useParams();
    const [order, setOrder] = useState(null);
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const token = { Authorization: `Bearer ${localStorage.getItem('token')}` };
                const response = await Services.getOrderDetails(orderId, token);
                setOrder(response.order); // Adjust this line based on your API response structure
                setUser(response.user);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchUserDetails();
    }, [orderId]);

    if (loading) {
        // Show skeleton loading state while fetching data
        return (
            <div>
                <table className={ViewUserClasses['user-table']}>
                    <thead>
                        <tr>
                            <th colSpan="2" className={ViewUserClasses['table-header']}>Customer Details</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan="2">
                                <div style={{ padding: '5px' }} className={ViewUserClasses['skeleton-viewuseroverview']}>
                                    <Skeleton variant="rectangular" width={300} height={30} />
                                    <Skeleton variant="rectangular" width={640} height={30} />
                                    <Skeleton variant="rectangular" width={300} height={30} />
                                    <Skeleton variant="rectangular" width={640} height={30} />
                                    <Skeleton variant="rectangular" width={300} height={30} />
                                    <Skeleton variant="rectangular" width={640} height={30} />
                                    <Skeleton variant="rectangular" width={300} height={30} />
                                    <Skeleton variant="rectangular" width={640} height={30} />
                                    <Skeleton variant="rectangular" width={300} height={30} />
                                    <Skeleton variant="rectangular" width={640} height={30} />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table className={ViewUserClasses['user-table']}>
                    <thead>
                        <tr>
                            <th colSpan="2" className={ViewUserClasses['table-header']}>Shipping Address</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan="2">
                                <div style={{ padding: '5px' }} className={ViewUserClasses['skeleton-viewuseroverview']}>
                                    <Skeleton variant="rectangular" width={300} height={30} />
                                    <Skeleton variant="rectangular" width={640} height={30} />
                                    <Skeleton variant="rectangular" width={300} height={30} />
                                    <Skeleton variant="rectangular" width={640} height={30} />
                                    <Skeleton variant="rectangular" width={300} height={30} />
                                    <Skeleton variant="rectangular" width={640} height={30} />
                                    <Skeleton variant="rectangular" width={300} height={30} />
                                    <Skeleton variant="rectangular" width={640} height={30} />
                                    <Skeleton variant="rectangular" width={300} height={30} />
                                    <Skeleton variant="rectangular" width={640} height={30} />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!order) {
        return <div>No order found.</div>;
    }

    // Parse the delivery address
    let deliveryAddress;
    try {
        deliveryAddress = JSON.parse(order.deliveryAddress);
    } catch (e) {
        deliveryAddress = {};
    }

    return (
        <div>
            <table className={ViewUserClasses['user-table']}>
                <thead>
                    <tr>
                        <th colSpan="2" className={ViewUserClasses['table-header']}>Customer Details</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className={ViewUserClasses['table-label']}>First Name</td>
                        <td className={ViewUserClasses['table-value']}>{user.firstName}</td>
                    </tr>
                    <tr>
                        <td className={ViewUserClasses['table-label']}>Last Name</td>
                        <td className={ViewUserClasses['table-value']}>{user.lastName}</td>
                    </tr>
                    <tr>
                        <td className={ViewUserClasses['table-label']}>Company</td>
                        <td className={ViewUserClasses['table-value']}>{user.company}</td>
                    </tr>
                    <tr>
                        <td className={ViewUserClasses['table-label']}>Email</td>
                        <td className={ViewUserClasses['table-value']}>{user.email}</td>
                    </tr>
                    <tr>
                        <td className={ViewUserClasses['table-label']}>Phone Number</td>
                        <td className={ViewUserClasses['table-value']}>{user.mobileNumber}</td>
                    </tr>
                </tbody>
            </table>
            <table className={ViewUserClasses['user-table']}>
                <thead>
                    <tr>
                        <th colSpan="2" className={ViewUserClasses['table-header']}>Shipping Address</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className={ViewUserClasses['table-label']}>First Name</td>
                        <td className={ViewUserClasses['table-value']}>{order.deliveryAddress.firstName || 'NA'}</td>
                    </tr>
                    <tr>
                        <td className={ViewUserClasses['table-label']}>Last Name</td>
                        <td className={ViewUserClasses['table-value']}>{order.deliveryAddress.lastName || 'NA'}</td>
                    </tr>
                    <tr>
                        <td className={ViewUserClasses['table-label']}>Company</td>
                        <td className={ViewUserClasses['table-value']}>{order.deliveryAddress.company || 'NA'}</td>
                    </tr>
                    <tr>
                        <td className={ViewUserClasses['table-label']}>Address</td>
                        <td className={ViewUserClasses['table-value']}>
                            {`${order.deliveryAddress.flatNo}, ${order.deliveryAddress.area}, ${order.deliveryAddress.city}, ${order.deliveryAddress.state} - ${order.deliveryAddress.pinCode}`}
                        </td>
                    </tr>
                    <tr>
                        <td className={ViewUserClasses['table-label']}>Phone Number</td>
                        <td className={ViewUserClasses['table-value']}>{order.deliveryAddress.mobileNumber || 'NA'}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default OrderCustomerShipping;
