import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Button, Step, StepLabel, Stepper, Breadcrumbs, Link, Typography, Stack } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { MdOutlineArrowBackIos } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import Layout from 'Components/Layout';
import AddUserOverview from '../addUser/AddUserOverView';
import AddUserAddress from '../addUser/AddUserAddress';
import AddUserDocuments from '../addUser/AddUserDocuments';
import ViewUserClasses from '../viewUsers/ViewUsers.module.css';
import CommonClasses from '../../../Styles/Common.module.css';
import AddUserClasses from './AddUser.module.css';
import { Services } from 'Services';
import { Error, Success } from 'Util/Toast';

const AddUser = () => {
    const navigate = useNavigate();
    const [activeStep, setActiveStep] = useState(0);
    const [userData, setUserData] = useState({
        firstName: '',
        lastName: '',
        company: '',
        email: '',
        password: '',
        confirmPassword: '',
        mobileNumber: '',
        deliveryAddress: '',
        city: '',
        state: '',
        area: '',
        pincode: '',
        flatNo: '',
        addressType: '',
        documents: []
    });
    const [errors, setErrors] = useState('');
    const [documentUploadStatus, setDocumentUploadStatus] = useState({
        'Aadhar Card': false,
        'Pan Card': false,
        'Drug License': false,
        'Support Documents': false
    });

    const steps = ['User Overview', 'User Address', 'User Documents'];

    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" onClick={() => navigate('/')}>
            Home
        </Link>,
        <Link underline="hover" key="2" color="inherit" onClick={() => navigate('/users')}>
            Users
        </Link>,
        <Typography key="3" color="text.primary">
            Add User
        </Typography>,
    ];

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const mobileRegex = /^[0-9]{10}$/;
    const pincodeRegex = /^\d{6}$/;

    const validate = (name, value) => {
        setErrors(prevErrors => {
            const newErrors = { ...prevErrors };

            if (!value) {
                newErrors[name] = `${name} is required`;
            } else {
                if (name === 'email' && !emailRegex.test(value)) {
                    newErrors[name] = 'Invalid email format';
                } else if (name === 'mobileNumber' && !mobileRegex.test(value)) {
                    newErrors[name] = 'Invalid mobile number format';
                } else if (name === 'pincode' && !pincodeRegex.test(value)) {
                    newErrors[name] = 'Invalid pin code format';
                } else {
                    delete newErrors[name];
                }

                // Add password confirmation validation
                if (name === 'password' || name === 'confirmPassword') {
                    const password = name === 'password' ? value : userData.password;
                    const confirmPassword = name === 'confirmPassword' ? value : userData.confirmPassword;

                    if (password !== confirmPassword) {
                        newErrors.confirmPassword = 'Passwords do not match';
                    } else {
                        delete newErrors.confirmPassword;
                    }
                }
            }

            return newErrors;
        });
    };

    const validateForm = () => {
        const newErrors = {};
        // Add validation for each step
        if (activeStep === 0) {
            if (!userData.firstName) newErrors.firstName = 'First Name is required *';
            if (!userData.lastName) newErrors.lastName = 'Last Name is required *';
            if (!userData.company) newErrors.company = 'Company is required *';
            if (!userData.mobileNumber) newErrors.mobileNumber = 'Mobile Number is required *';
            if (!emailRegex.test(userData.email)) newErrors.email = 'Invalid email format *';
            if (!mobileRegex.test(userData.mobileNumber)) newErrors.mobileNumber = 'Invalid mobile number format *';
            if (!userData.email) newErrors.email = 'Email is required *';
            if (!userData.password) newErrors.password = 'Password is required *';
            if (!userData.confirmPassword) newErrors.confirmPassword = 'Confirm Password is required *';
            if (userData.password !== userData.confirmPassword) newErrors.confirmPassword = 'Passwords do not match *';
            // Add other required fields for User Overview
        }
        if (activeStep === 1) {
            if (!userData.deliveryAddress) newErrors.deliveryAddress = 'Delivery Address is required';
            if (!userData.city) newErrors.city = 'City is required';
            if (!userData.state) newErrors.state = 'State is required';
            if (!userData.area) newErrors.area = 'Area is required';
            if (!userData.pincode) newErrors.pincode = 'Pin Code is required';
            else if (!pincodeRegex.test(userData.pincode)) newErrors.pincode = 'Invalid pin code format';
            if (!userData.flatNo) newErrors.flatNo = 'Flat No is required';
            if (!userData.addressType) newErrors.addressType = 'Address Type is required';
            // Add other required fields for User Address
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleNext = () => {
        if (validateForm()) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const checkUploadStatus = (docName, isUploaded) => {
        setDocumentUploadStatus(prevStatus => ({
            ...prevStatus,
            [docName]: isUploaded
        }));
    };

    const handleSaveChanges = async () => {
        try {
            // Check if all mandatory documents are uploaded
            const allDocumentsUploaded = Object.values(documentUploadStatus).every(status => status);

            if (!allDocumentsUploaded) {
                setErrors('Please upload all mandatory documents.')
                console.error('Please upload all mandatory documents.');
                // Optionally show an error message or handle validation
                return;
            }

            // Prepare the payload data
            const payload = {
                ...userData,
                documents: userData.documents.map(doc => ({
                    name: doc.name,
                    document: doc.document,
                    expiryDate: doc.expiryDate,
                    documentNumber: doc.documentNumber,
                    status: 0,
                    documentType: doc.documentType
                }))
            };

            // Call createUser API
            const token = { Authorization: `token ${localStorage.getItem('token')}` };
            const response = await Services.createUser(payload, token);
            console.log('User created:', response); // Log the response from the API
            // Success(response.Message)
            // Optionally, navigate to another page after saving changes
            // navigate('/users'); // Example: Navigate to the users page
            if (response.Status === 1) {
                Success(response.Message);
                navigate('/users');
            } else if( response.Status===0){
                Error(response.Message);
            }
        } catch (error) {
            Error('Error creating user');
            console.error('Error creating user:', error);
        }
    };

    const getStepContent = (stepIndex) => {
        switch (stepIndex) {
            case 0:
                return <AddUserOverview userData={userData} setUserData={setUserData} errors={errors} validate={validate} setErrors={setErrors} />;
            case 1:
                return <AddUserAddress userData={userData} setUserData={setUserData} errors={errors} validate={validate} setErrors={setErrors} />;
            case 2:
                return <AddUserDocuments userData={userData} setUserData={setUserData} errors={errors} validate={validate} setErrors={setErrors} checkUploadStatus={checkUploadStatus} />;
            default:
                return 'Unknown stepIndex';
        }
    };

    return (
        <Layout Active={'Users'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Users</title>
            </Helmet>
            <div className={AddUserClasses['main-of-tables']}>
                <div className={AddUserClasses['new-main-div']}>
                    <div className={ViewUserClasses['main-on-ctrl-btns']}>
                        <div className={CommonClasses['control-btns']}>
                            <Button className={CommonClasses["button-for-add"]} onClick={() => navigate(-1)}><MdOutlineArrowBackIos />Back</Button>
                        </div>
                        <Stack spacing={2}>
                            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                                {breadcrumbs}
                            </Breadcrumbs>
                        </Stack>
                    </div>
                </div>
                <div className={AddUserClasses['main-right-add-btns']}>
                    {activeStep !== 0 && (
                        <button className={AddUserClasses['main-right-cancel-btns']} onClick={handleBack} style={{ marginRight: 10 }}>
                            Back
                        </button>
                    )}
                    {activeStep !== steps.length - 1 ? (
                        <button className={AddUserClasses['next-stepper-btn']} variant="contained" onClick={handleNext}>
                            Continue to next step
                        </button>
                    ) : (
                        <button className={AddUserClasses['main-right-save-btns']} onClick={handleSaveChanges}>
                            Save Changes
                        </button>
                    )}
                </div>
                <Stepper activeStep={activeStep}>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <div className={AddUserClasses['main-btn-div']}>
                    {getStepContent(activeStep)}
                </div>
            </div>
        </Layout>
    );
};

export default AddUser;
